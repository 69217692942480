

 export const resumeCardData = [
  {
    header:"Java Expert",
    experience:"0-2 years",
    about:"Strong hands-on Java design and coding experience. Good with Spring, Spring Boot Framework and Hibernate.",
    location:"Banglore/ Pune/ Gurugram/ Hyderabad[...]"
  },
  {
    header:"DevOps Engineer",
    experience:"0-1 years",
    about:"Strong knowledge of systems and network fundamental concepts. build scalable, efficient cloud infrastructure.",
    location:"Banglore/ Pune/ Gurugram/ Hyderabad[...]"
  },
  {
    header:"Java Expert",
    experience:"0-2 years",
    about:"Strong hands-on Java design and coding experience. Good with Spring, Spring Boot Framework and Hibernate.",
    location:"Banglore/ Pune/ Gurugram/ Hyderabad[...]"
  },
  {
    header:"DevOps Engineer",
    experience:"0-1 years",
    about:"Strong knowledge of systems and network fundamental concepts. build scalable, efficient cloud infrastructure.",
    location:"Banglore/ Pune/ Gurugram/ Hyderabad[...]"
  },
  {
    header:"Java Expert",
    experience:"0-2 years",
    about:"Strong hands-on Java design and coding experience. Good with Spring, Spring Boot Framework and Hibernate.",
    location:"Banglore/ Pune/ Gurugram/ Hyderabad[...]"
  },
  {
    header:"DevOps Engineer",
    experience:"0-1 years",
    about:"Strong knowledge of systems and network fundamental concepts. build scalable, efficient cloud infrastructure.",
    location:"Banglore/ Pune/ Gurugram/ Hyderabad[...]"
  },
  {
    header:"Java Expert",
    experience:"0-2 years",
    about:"Strong hands-on Java design and coding experience. Good with Spring, Spring Boot Framework and Hibernate.",
    location:"Banglore/ Pune/ Gurugram/ Hyderabad[...]"
  },
  {
    header:"DevOps Engineer",
    experience:"0-1 years",
    about:"Strong knowledge of systems and network fundamental concepts. build scalable, efficient cloud infrastructure.",
    location:"Banglore/ Pune/ Gurugram/ Hyderabad[...]"
  },
]