import React from 'react';
import "../styles/Gallery.css";
import Layout from '../components/Layout/Layout';
import { Grid } from '@mui/material';
import gallery_img_1 from "../assets/gallery_img_1.png";

const Gallery = () => {
  return (
    <Layout>
      <section className='gallery_section'>
          <div className="gallery_hedaer_box">
              <h3>Discover the Journey</h3>
              <h4>Capturing Success at HyperNxt Academy</h4>
          </div>

          <Grid container className='gallery_container'>
              <Grid item md={6} xs={11} className='gallery_left_content'>
                <img src={gallery_img_1} alt="gallery_img_1" />
              </Grid>
              <Grid item md={5.2} xs={11} className='gallery_right_content'>
                <p>Explore our vibrant campus life and witness the transformation of students as they master industry-leading skills. From hands-on workshops to graduation ceremonies, our gallery showcases the milestones and moments that define the HyperNxt experience. See the dedication, innovation, and achievements that make our academy a leader in shaping future professional</p>
                <p>Experience the collaboration and creativity that fuel our community, and get a glimpse of the impact our graduates are making in the industry. </p>
              </Grid>
          </Grid>
      </section>
    </Layout>
  )
}

export default Gallery;
