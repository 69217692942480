import React from 'react';
import Layout from "../components/Layout/Layout.jsx";
import "../styles/Courses.css";
import { Grid } from '@mui/material';
import { FaAngleRight } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import { leadingCourses } from "../Data/CoursesData.js";
import { useNavigate } from 'react-router-dom';
import FAQ from '../components/FAQ/FAQ.jsx';
import Frame from '../assets/Frame.png'


const Courses = () => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/course_module")
  }

  const handleCourseEnroll = () => {
    navigate("/explore")
  }

  return (
    <>
      <Layout>
        <section className='courses_section'>
          <div className="header_box">
           <div className="course-heading">
            <h5>Explore Categories / IIT JEE</h5>
            <p>Abasko Learning provides the best online coaching for IIT JEE preparation, with expert faculty, live and recorded classes, and in-depth study materials. We offer courses for Class 11, Class 12, and repeaters, all at affordable fees. Enroll with Abasko Learning today and gear up for IIT JEE success!</p>
            <img src={Frame} alt="" className='frame' />
            </div> 

            <Grid container className='job_leading_course_container'>
              {
                leadingCourses?.map((item) => (
                  <div className="job_leading_course_card" key={item.id}>
                    <Grid item sm={4} xs={12} className='card_left'>
                      <h3>COURSE</h3>
                      <h2>{item.courseName}</h2>
                      <h4 onClick={handleClick} className='viw_modules'>view modules <FaAngleRight className='view_modules_right_icon' /></h4>
                    </Grid>
                    <Grid item sm={8} xs={12} className='card_right'>
                      <div className="upper_content">
                        <h4>DURATION: {item.duration}</h4>
                        <h2>{item.courseName}</h2>
                        <div className="progress_bar">
                          <div className="inner_progress" style={{ width: `${item.width}` }}></div>
                        </div>
                        <p>{item.daysLeft} days left</p>
                      </div>

                      <div className="lower_btn_container">

                        <button onClick={handleCourseEnroll}>
                          Explore
                        </button>&nbsp;&nbsp;&nbsp;
                        <button onClick={handleCourseEnroll}>
                          <img src={item.enrollIcon} alt="enroll_icons" />
                          {item.isEnroll}
                        </button>

                      </div>
                    </Grid>
                  </div>
                ))
              }
            </Grid>


          </div>

        </section>
        <div className="faqqq" style={{marginTop: "-150px"}}>
          <FAQ />
          </div>
      </Layout>
    </>
  )
}

export default Courses;
