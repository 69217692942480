
export const accordiansData =[
  {
    que:"What is the course curriculum flow?",
    ans:"The course curriculum at HyperNxt Academy is carefully crafted to provide a comprehensive learning experience. It covers all essential topics, from foundational concepts to advanced skills, ensuring that you gain practical knowledge and hands-on experience. Each module is designed to build upon the previous one, allowing you to develop a deep understanding of the subject matter"
  },
  {
    que:"How often do students get to work on real-time projects?",
    ans:"The course curriculum at HyperNxt Academy is carefully crafted to provide a comprehensive learning experience. It covers all essential topics, from foundational concepts to advanced skills, ensuring that"
  },
  {
    que:"Can I get a free demo class?",
    ans:"The course curriculum at HyperNxt Academy is carefully crafted to provide a comprehensive learning experience. It covers all essential topics, from foundational concepts to advanced skills, ensuring that"
  },
  {
    que:"Do you have a monthly plan?",
    ans:"The course curriculum at HyperNxt Academy is carefully crafted to provide a comprehensive learning experience. It covers all essential topics, from foundational concepts to advanced skills, ensuring that"
  },
  {
    que:"Do you have discounts for students courses?",
    ans:"The course curriculum at HyperNxt Academy is carefully crafted to provide a comprehensive learning experience. It covers all essential topics, from foundational concepts to advanced skills, ensuring that"
  },
  {
    que:"What forms of payment do you accept?",
    ans:"The course curriculum at HyperNxt Academy is carefully crafted to provide a comprehensive learning experience. It covers all essential topics, from foundational concepts to advanced skills, ensuring that"
  },
  {
    que:"Can I cancel my subscription?",
    ans:"The course curriculum at HyperNxt Academy is carefully crafted to provide a comprehensive learning experience. It covers all essential topics, from foundational concepts to advanced skills, ensuring that"
  },
  {
    que:"How do I switch from a Basic plan to a Premium plan?",
    ans:"The course curriculum at HyperNxt Academy is carefully crafted to provide a comprehensive learning experience. It covers all essential topics, from foundational concepts to advanced skills, ensuring that"
  },
  {
    que:"What is the difference between Basic plan and premium plan?",
    ans:"The course curriculum at HyperNxt Academy is carefully crafted to provide a comprehensive learning experience. It covers all essential topics, from foundational concepts to advanced skills, ensuring that"
  },
  {
    que:"What is the course refund policy?",
    ans:"The course curriculum at HyperNxt Academy is carefully crafted to provide a comprehensive learning experience. It covers all essential topics, from foundational concepts to advanced skills, ensuring that"
  },
]