export const moduleCourseData = [
  {
    moduleNumber:1,
    moduleName:"Getting Started with MERN Stack",
    duration:"1hr 36 min",
    listData:[
      {
        list:"What is the MERN Stack?",
        timing:"10 mins"
      },
      {
        list:"Key Features of the MERN Stack",
        timing:"7 mins"
      },
      {
        list:"Real-World Applications of MERN Stack",
        timing:"15 mins"
      },
      {
        list:"Setting Up the MERN Stack Environment",
        timing:"20 mins"
      },
      {
        list:"Installing MongoDB on Windows, MacOS, and Linux",
        timing:"10 mins"
      },
      {
        list:"Setting Up Express.js",
        timing:"8 mins"
      },
      {
        list:"Introduction to React",
        timing:"12 mins"
      },
      {
        list:"Introduction to Node.js",
        timing:"14 mins"
      },
    ]
  },
  {
    moduleNumber:2,
    moduleName:"MongoDB Basics",
    duration:"1hr 36 min",
    listData:[
      {
        list:"What is the MERN Stack?",
        timing:"10 mins"
      },
      {
        list:"Key Features of the MERN Stack",
        timing:"7 mins"
      },
      {
        list:"Real-World Applications of MERN Stack",
        timing:"15 mins"
      },
      {
        list:"Setting Up the MERN Stack Environment",
        timing:"20 mins"
      },
      {
        list:"Installing MongoDB on Windows, MacOS, and Linux",
        timing:"10 mins"
      },
      {
        list:"Setting Up Express.js",
        timing:"8 mins"
      },
      {
        list:"Introduction to React",
        timing:"12 mins"
      },
      {
        list:"Introduction to Node.js",
        timing:"14 mins"
      },
    ]
  },
  {
    moduleNumber:3,
    moduleName:"Express.js Fundamentals",
    duration:"1hr 36 min",
    listData:[
      {
        list:"What is the MERN Stack?",
        timing:"10 mins"
      },
      {
        list:"Key Features of the MERN Stack",
        timing:"7 mins"
      },
      {
        list:"Real-World Applications of MERN Stack",
        timing:"15 mins"
      },
      {
        list:"Setting Up the MERN Stack Environment",
        timing:"20 mins"
      },
      {
        list:"Installing MongoDB on Windows, MacOS, and Linux",
        timing:"10 mins"
      },
      {
        list:"Setting Up Express.js",
        timing:"8 mins"
      },
      {
        list:"Introduction to React",
        timing:"12 mins"
      },
      {
        list:"Introduction to Node.js",
        timing:"14 mins"
      },
    ]
  },
  {
    moduleNumber:4,
    moduleName:"React Basics",
    duration:"1hr 36 min",
    listData:[
      {
        list:"What is the MERN Stack?",
        timing:"10 mins"
      },
      {
        list:"Key Features of the MERN Stack",
        timing:"7 mins"
      },
      {
        list:"Real-World Applications of MERN Stack",
        timing:"15 mins"
      },
      {
        list:"Setting Up the MERN Stack Environment",
        timing:"20 mins"
      },
      {
        list:"Installing MongoDB on Windows, MacOS, and Linux",
        timing:"10 mins"
      },
      {
        list:"Setting Up Express.js",
        timing:"8 mins"
      },
      {
        list:"Introduction to React",
        timing:"12 mins"
      },
      {
        list:"Introduction to Node.js",
        timing:"14 mins"
      },
    ]
  },
  {
    moduleNumber:5,
    moduleName:"Node.js Basics",
    duration:"1hr 36 min",
    listData:[
      {
        list:"What is the MERN Stack?",
        timing:"10 mins"
      },
      {
        list:"Key Features of the MERN Stack",
        timing:"7 mins"
      },
      {
        list:"Real-World Applications of MERN Stack",
        timing:"15 mins"
      },
      {
        list:"Setting Up the MERN Stack Environment",
        timing:"20 mins"
      },
      {
        list:"Installing MongoDB on Windows, MacOS, and Linux",
        timing:"10 mins"
      },
      {
        list:"Setting Up Express.js",
        timing:"8 mins"
      },
      {
        list:"Introduction to React",
        timing:"12 mins"
      },
      {
        list:"Introduction to Node.js",
        timing:"14 mins"
      },
    ]
  },
  {
    moduleNumber:6,
    moduleName:"Building a Full MERN Application",
    duration:"1hr 36 min",
    listData:[
      {
        list:"What is the MERN Stack?",
        timing:"10 mins"
      },
      {
        list:"Key Features of the MERN Stack",
        timing:"7 mins"
      },
      {
        list:"Real-World Applications of MERN Stack",
        timing:"15 mins"
      },
      {
        list:"Setting Up the MERN Stack Environment",
        timing:"20 mins"
      },
      {
        list:"Installing MongoDB on Windows, MacOS, and Linux",
        timing:"10 mins"
      },
      {
        list:"Setting Up Express.js",
        timing:"8 mins"
      },
      {
        list:"Introduction to React",
        timing:"12 mins"
      },
      {
        list:"Introduction to Node.js",
        timing:"14 mins"
      },
    ]
  },
  {
    moduleNumber:7,
    moduleName:"Capstone Project",
    duration:"2hr 30 min",
    listData:[
      {
        list:"What is the MERN Stack?",
        timing:"10 mins"
      },
      {
        list:"Key Features of the MERN Stack",
        timing:"7 mins"
      },
      {
        list:"Real-World Applications of MERN Stack",
        timing:"15 mins"
      },
      {
        list:"Setting Up the MERN Stack Environment",
        timing:"20 mins"
      },
      {
        list:"Installing MongoDB on Windows, MacOS, and Linux",
        timing:"10 mins"
      },
      {
        list:"Setting Up Express.js",
        timing:"8 mins"
      },
      {
        list:"Introduction to React",
        timing:"12 mins"
      },
      {
        list:"Introduction to Node.js",
        timing:"14 mins"
      },
    ]
  },
]