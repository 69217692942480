import React from 'react';
import Layout from "../components/Layout/Layout.jsx";
import "../styles/Careers.css";
import JobResume from '../components/JobResume/JobResume.jsx';

const Careers = () => {
  return (
    <>
      <Layout>
        <section className='carrers_banner'>
          <h4>Explore Opportunities</h4>
          <h5>Apply Directly for Jobs with HyperNxt Academy</h5>
        </section>

        <JobResume/>
        
      </Layout>
    </>
  )
}

export default Careers;
