/** @format */

import React from "react";
import Layout from "../components/Layout/Layout";
import "../styles/PrivacyPolicy.css";
function PrivacyPolicy() {
  return (
    <>
      <Layout>
        <div id="privacypolicymain">
          <h2 id="privacymaintext">Privacy Policy</h2>
          <div id="maintext-privacy">
            <h3 id="privacymaintext-two">
              At Abasko Learning, a brand of Abaskeo Solution Pvt Ltd, we are committed to safeguarding the privacy of our students, parents, staff, and website visitors. This Privacy Policy outlines how we collect, use, and protect your personal information when you interact with our platform, whether you are accessing educational content, registering for courses, or using any of our digital services.
            </h3><br />
            <h3 id="privacymaintext-two">
              By using the Abasko Learning website and services, you agree to the terms outlined in this Privacy Policy.
            </h3>
          </div>
          <div id="privacy-startmain">
            <h3 id="Collectheading">1.  Information We Collect</h3>
            <p id="Collectpara">
              We collect different types of information depending on how you interact with Abasko Learning. The types of personal information we may collect include:
            </p>
            <ul id="Collectul">
              <li id="Collectid">
                Personal Identification Information: Full name, email address, phone number, date of birth, and postal address.
              </li>
              <li id="Collectid">
                Educational Information: Courses enrolled, academic performance, and learning preferences.
              </li>
              <li id="Collectid">
                Payment Information: Billing details, payment methods, and transaction history.
              </li>
              <li id="Collectid">
                Technical Information: IP address, browser type, device information, and interaction data through cookies and similar technologies.
              </li>
            </ul>
          </div>
          <div id="privacy-startmain">
            <h3 id="Collectheading">2.  How We Use Your Information</h3>
            <p id="Collectpara">The information collected is primarily used to:</p>
            <ul id="Collectul">
              <li id="Collectid">
                Provide access to educational content, courses, and related services.
              </li>
              <li id="Collectid">
                Improve and personalize your learning experience.
              </li>
              <li id="Collectid">
                Process payments for course enrollments and other transactions.
              </li>
              <li id="Collectid">
                Communicate important updates, offers, and other relevant information.
              </li>
              <li id="Collectid">
                Ensure compliance with legal obligations and our terms of service.
              </li>
              <li id="Collectid">
                Enhance the website’s functionality and user experience.
              </li>
            </ul>
          </div>
          <div id="privacy-startmain">
            <h3 id="Collectheading">3.  Data Security</h3>
            <p id="Securitypara">
              Abaskeo Solution Pvt Ltd values the security of your personal data. We use industry-standard measures such as encryption, secure servers, and access control protocols to protect your data from unauthorized access or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
            </p>
          </div>
          {/* <div id="privacy-startmain">
            <h3 id="Collectheading">5.  Your Rights</h3>
            <p id="Collectpara">You have the right to:</p>
            <ul id="Collectul">
              <li id="Collectid">
                Access, update, or correct your personal information via your
                account settings
              </li>
              <li id="Collectid">
                Request the deletion of your account and associated personal
                information, subject to any legal obligations we may have
              </li>
              <li id="Collectid">
                Opt-out of marketing communications by following the unsubscribe
                link in any email or updating your preferences in your account
                settings
              </li>
            </ul>
          </div> */}
          <div id="privacy-startmain">
            <h3 id="Collectheading">4. Cookie Policy</h3>
            <p id="Securitypara">
              Abasko Learning uses cookies and similar tracking technologies to enhance your browsing experience, remember user preferences, and provide personalized content. Cookies may also be used for analytical purposes to understand how users interact with our site. You can control cookie preferences through your browser settings. Please note that disabling cookies may affect certain functionalities of the site.
            </p>
          </div>
          <div id="privacy-startmain">
            <h3 id="Collectheading">5.  How We Share Your Information</h3>
            <p id="Collectpara">
            We do not sell or share your personal information with third parties except in the following situations:
            </p>
            <ul id="Collectul">
              <li id="Collectid">
              Service Providers: We may share information with trusted service providers who assist in providing educational services, payment processing, and website operations.
              </li>
              <li id="Collectid">
              Legal Requirements: If required by law, we may disclose your information to comply with legal obligations or respond to lawful requests from government authorities.
              </li>
              <li id="Collectid">
              Business Transfers: In the event of a merger, acquisition, or restructuring of Abaskeo Solution Pvt Ltd, your information may be transferred as part of the transaction.
              </li>
            </ul>
          </div>
          <div id="privacy-startmain">
            <h3 id="Collectheading">6.  Your Rights</h3>
            <p id="Collectpara">
            As a user, you have the right to:
            </p>
            <ul id="Collectul">
              <li id="Collectid">
              Access, update, or delete your personal information at any time.
              </li>
              <li id="Collectid">
              Opt out of receiving marketing communications from Abasko Learning.
              </li>
              <li id="Collectid">
              Request the restriction or cessation of your data processing.
              </li>
              <li id="Collectid">
              Withdraw consent where data processing is based on consent.
              </li>
            </ul>
            <p>To exercise any of these rights, please contact us at [Insert Contact Information].</p>
          </div>
          <div id="privacy-startmain">
            <h3 id="Collectheading">7. Third-Party Links</h3>
            <p id="Securitypara">
            Our website may contain links to third-party websites. Abasko Learning is not responsible for the privacy practices or the content of such websites. We encourage you to read their privacy policies before providing any personal information.
            </p>
          </div>
          <div id="privacy-startmain">
            <h3 id="Collectheading">8. Updates to This Privacy Policy</h3>
            <p id="Securitypara">
            We may update this Privacy Policy from time to time to reflect changes in legal requirements or our practices. All updates will be posted on this page, and the “Effective Date” at the top will be revised accordingly. We encourage you to review this policy regularly to stay informed of any changes.
            </p>
          </div>
          <div id="privacy-startmain">
            <h3 id="Collectheading">Contact Us</h3>
            <p id="Securitypara">
              If you have any questions about this Privacy Policy or how we
              handle your information, please contact us at:
            </p>
            <p id="Securitypara"></p>
            <p id="Securitypara">Abasko Learning (Abaskeo Solution Pvt Ltd)  <br />Email: <a href="mailto:abasko@learning.com">abasko@learning.com</a><br />Phone: <a href="tel:918685911755">8685911755</a></p>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default PrivacyPolicy;
