import React from 'react'
import Layout from '../components/Layout/Layout';
import "../styles/CyberSecurity.css";
import { FaAngleRight } from "react-icons/fa6";


const CyberSecurity = () => {
  return (
    <Layout>
      <section className='cyber_security_section'>
        <div className="cyber_security_content">
        <h5>Certification Course on</h5>
        <h4>Cyber Security</h4>

        <button className='cyber_go_to_course'>Go To Course <FaAngleRight /></button>
        </div>
      </section>
    </Layout>
  )
}

export default CyberSecurity
