import enrolled_icon from "../assets/enroolled_icon.png";

import enroll_now_icon from "../assets/enroll_now_icon.png";


export const leadingCourses = [
  {
    id:1,
    courseName:"IIT JEE",
    duration:"6months",
    daysLeft:"48/180",
    isEnroll:"Enrolled",
    enrollIcon:enrolled_icon,
    width:"70%"
  },
  {
    id:2,
    courseName:"NEET",
    duration:"6months",
    daysLeft:"180/180",
    isEnroll:"Enrol Now",
    enrollIcon:enroll_now_icon,
    width:"2%"
  },
  {
    id:3,
    courseName:"CA",
    duration:"6months",
    daysLeft:"180/180",
    isEnroll:"Enrol Now",
    enrollIcon:enroll_now_icon,
    width:"2%"
  },
  {
    id:4,
    courseName:"UPSE",
    duration:"6months",
    daysLeft:"180/180 ",
    isEnroll:"Enrol Now",
    enrollIcon:enroll_now_icon,
    width:"2%"
  },
  {
    id:5,
    courseName:"NISER",
    duration:"6months",
    daysLeft:"48/180",
    isEnroll:"Enrol Now",
    enrollIcon:enroll_now_icon,
    width:"2%"
  },
  {
    id:6,
    courseName:"AIIMS",
    duration:"6months",
    daysLeft:"48/180",
    isEnroll:"Enrol Now",
    enrollIcon:enroll_now_icon,
    width:"2%"
  },
 
]