import React from 'react';
import Layout from "../components/Layout/Layout.jsx";
import "../styles/Explore.css";
import { Grid } from '@mui/material';
import { FaAngleRight } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import { leadingCourses } from "../Data/CoursesData.js";
import { useNavigate } from 'react-router-dom';
import FAQ from '../components/FAQ/FAQ.jsx';
import Frame from '../assets/Frame.png'
import Budha from '../assets/budha.png'

// mui timeline

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

const Courses = () => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/course_module")
  }

  const handleCourseEnroll = () => {
    navigate("/mern_program")
  }

  return (
    <>
      <Layout>
        <section className='courses_section'>
          <div className="header_box">
            <div className="course-heading">
              <h5>Explore Courses</h5>
              <h4>ABASKO JEE 3.0</h4>
              <p>Course Discounted Price</p>
              <div className="price_panel">
                <h4>₹ 36,000</h4>
                <h6>₹ 40,000</h6>
                <div className="discount">10% OFF</div>
              </div>
              <img src={Frame} alt="" className='frame' />
            </div>

          </div>

          <div className="batch-details">
            <h2>Batch Detail</h2>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  <TimelineConnector color="primary" />
                </TimelineSeparator>
                <TimelineContent>PDF notes from each class will be uploaded on the Abasko App.</TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>Daily practice problems with video solutions in a quiz format.</TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>Scheduled tests aligned with the course planner.</TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>Full course access until JEE 2025 exams.</TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>The batch price includes the registration fee, which will be detailed on the invoice. Access to notes, previous year questions, mock tests, and test series may vary depending on the batch.</TimelineContent>
              </TimelineItem>
            </Timeline>
          </div>
          <div className="batch-details2">
            <h2>This Batch Includes</h2>
            <div className="details-cont">
              <div className="details">
                <h1>50+</h1>
                <h5>LIVE<br></br>CLASSES</h5>
              </div>
              <div className="details">
                <h1>100+</h1>
                <h5>RECORDED<br></br>CLASSES</h5>
              </div>
              <div className="details">
                <h1>10+</h1>
                <h5>MONTLY<br></br>
                  PRACTICE TESTS</h5>
              </div>
            </div>
          </div>
          <div className="batch-details2">
            <h2>Our Trainers</h2>
            <div className="details-cont">
              <div className="trainers">
                <h4>Akash Mehta</h4>
                <p>IIT JEE Qualified, GATE Qualified</p>
                <p>(AIR 511)</p>
                <img src={Budha} alt="" />
              </div>
              <div className="trainers">
                <h4>Akash Mehta</h4>
                <p>IIT JEE Qualified, GATE Qualified</p>
                <p>(AIR 511)</p>
                <img src={Budha} alt="" />
              </div>
              <div className="trainers">
                <h4>Akash Mehta</h4>
                <p>IIT JEE Qualified, GATE Qualified</p>
                <p>(AIR 511)</p>
                <img src={Budha} alt="" />
              </div>
            </div>
          </div>
        </section>

        <div className="faqqq" style={{ marginTop: "0px" }}>
          <FAQ />
        </div>
      </Layout>
    </>
  )
}

export default Courses;
