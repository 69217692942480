import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import GoTop from '../GoTop/GoTop';

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div style={{ paddingTop: '10vh' }}>
        {children}
        <GoTop />
      </div>
      <Footer />

    </>
  )
}

export default Layout
