import React from 'react';
import "../styles/ContactUs.css";
import Layout from '../components/Layout/Layout';
import contact_us_banner from "../assets/contct_us_side_banner.png";
import { GoMail } from "react-icons/go";
import { IoLocationSharp } from "react-icons/io5";
import { FaPhone } from "react-icons/fa6";
import { MdMail } from "react-icons/md";
import telegram_icon_img from "../assets/telegram_icon_img.png";
import contact_info_card_img from "../assets/contact_info_right_div_logo.png";

import contact_info_linkdin_icon from "../assets/contact_info_linkdin_icon.png";

import contact_info_instagram_icon from "../assets/contact_info_instagram_icon.png";

import contact_info_tweeter_icon from "../assets/contact_info_tweeter_icon.png";
import { useNavigate } from 'react-router-dom';

const ContactUs = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/submitEnquire');
  };
  return (
    <Layout>
      <section className='contactus_section'>
        <div className="left_content">
          <h4>Get In Touch</h4>
          <p>Reach out to us for any inquiries or assistance. Fill out the form or contact us directly, and we'll respond promptly.</p>
        </div>

        <div className="right_content">
          <img src={contact_us_banner} alt="contact_us_banner" />
        </div>
      </section>

      <section className='contact_message_section'>
        <div className="contact_mesaage_card">

          <div className="send_message">
            <div className="heading_box">
              <h4>Send a Message</h4>
              <GoMail />
            </div>

            <form action="" className='contact_us_form_box'>

              <div className="input_box_wrapper">

                <div className="input_box">
                  <label htmlFor="">Name</label>
                  <input type="text" />
                </div>
                <div className="input_box">
                  <label htmlFor="">E-mail</label>
                  <input type="email" />
                </div>
              </div>

              <div className="input_box_wrapper">
                <div className="input_box">
                  <label htmlFor="">Contact Number</label>
                  <input type="text" />
                </div>
                <div className="input_box">
                  <label htmlFor="">City</label>
                  <input type="email" />
                </div>
              </div>

              <div className="input_box_wrapper">
                <div className="input_box_textarea">
                  <label htmlFor="">Message</label>
                  <textarea placeholder='Type something .' name="" id="" />
                </div>
              </div>

              <div className="tele_submit_btn_wrapper">
                <div onClick={handleNavigate} className="tele_submit_btn" >
                  <img src={telegram_icon_img} alt="telegram_icon_img" />
                </div>
              </div>
            </form>
          </div>

          <div className="contact_information">

            <div className="contact_info_add_data">
              <h4>Contact Information</h4>

              <h5><IoLocationSharp className='location_icon' />BTM Layout, 2nd Stage, Banglore, Karnataka 560076</h5>

              <h5><FaPhone className='phone_icon' />+91 7672477256</h5>

              <h5><MdMail className='mail_icon' /> academy@therhypernxt.com</h5>
            </div>

            <div className="contact_info_icon_box">
              <img src={contact_info_linkdin_icon} alt="contact_info_linkdin_icon" />

              <img src={contact_info_instagram_icon} alt="contact_info_instagram_icon" />

              <img src={contact_info_tweeter_icon} alt="contact_info_tweeter_icon" />
            </div>

            <div className="contact_inf_img_box">
              <img src={contact_info_card_img} alt="contact_info_card_img" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactUs;
