import React, { useRef } from 'react';
import "../styles/Home.css";
import Layout from "../components/Layout/Layout.jsx";
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import OurCources from '../components/ourCources/ourCources.jsx';
import Mentors from '../components/mentors/mentors.jsx';
import FAQ from '../components/FAQ/FAQ.jsx';
import Reviews from '../components/reviews/reviews.jsx';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

import LinePic from '../assets/Group.png';
import Garata from '../assets/garata.png';
import Hero1 from '../assets/heropic1.png';


import { useNavigate } from 'react-router-dom';

const Home = () => {
  const courseCertification = useRef(null); 

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/getAdmission");
  };

  const handleToCertificationCourse = () => {
    courseCertification.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Layout>
        <div className="home">
        <section className='heroSection'>
          <div className="texts">
            <h2>Join ABASKO Learning today and<br></br>take the first step towards a</h2>
            <h1>bright future!</h1>
            <p>Achieve your dreams with comprehensive, high-quality courses designed by<br></br>experts. Whether you're aiming for top engineering, medical, civil services, or<br></br>chartered accountancy careers</p>
          </div>
          <img src={LinePic} alt="" className='linePic' />
          <Link to={'/category' }><Button variant="contained" className='herobtn'>Explore Categories <ArrowCircleRightIcon className='iconn' /></Button></Link>
          <img src={Garata} alt="" className='whitebar' />
          <img src={Hero1} alt="" className='hero' />
          </section>
          <section className='joinAcademy'>
            <h1>Join Our Academies</h1>
            <p>Discover our diverse academies and enhance your skills with expert guidance. Download the Abasko<br></br>Course Brochure now and begin your learning journey!</p>
            <Button variant="contained">Download Brochure</Button>
          </section>
          <OurCources />
          <Mentors />
          {/* the review part will be avalable when the data was there
          <Reviews /> */}
          <FAQ />
          </div>

      </Layout>
    </>
  );
};

export default Home;
