import React from 'react';
import "./FAQ.css";
import CourcePoster from "../../assets/courcePoster.png";
import Star from "../../assets/Star 3.png";
import Enroll from "../../assets/image 57.png";
import Button from '@mui/material/Button';


const OurCources = () => {
  const cuetFAQs = [
    {
      question: "What is a CUET exam?",
      answer: "CUET stands for Common University Entrance Test; it is conducted at the national level by NTA for various undergraduate program admissions of central, state, and private universities."
    },
    {
      question: "What is the eligibility for CUET?",
      answer: "For CUET, candidates shall have passed Class 12 or an examination equivalent to it from a recognized Board. The required subject and percentage in every participating university may vary for different courses."
    },
    {
      question: "What is the CUET exam pattern?",
      answer: "CUET Exam is an objective-type test with MCQs. It has sections of languages, domain-specific subjects, general tests, and negative markings on it."
    },
    {
      question: "What is the CUET syllabus?",
      answer: "It depends upon the chosen subject, but generally, it will be from Class 12 in the case of domain subjects besides general knowledge and language and reasoning skills."
    },
    {
      question: "When are the CUET exam dates?",
      answer: "CUET 2024 examinations will be held in May. Dates will be notified on the official NTA website."
    },
    {
      question: "How do I apply for CUET 2024?",
      answer: "One should apply for CUET 2024 through the official website of CUET NTA. The application form will require personal information on the candidate, academic information, and preferred universities and subjects."
    },
    {
      question: "How is the CUET score calculated?",
      answer: "The CUET score will be determined by the marks obtained at the entrance. There are 5 marks for correct answers, while for every wrong answer, 1 mark is deducted."
    },
    {
      question: "What is the CUET result, and how does one check it?",
      answer: "CUET results are announced on the NTA website. One can log in with their application number and password to check their results."
    },
    {
      question: "What is the CUET Admission Process?",
      answer: "After declaring the result, each participating university will declare a merit list. Admissions are granted based on the merit list and requirements of the particular university."
    },
    {
      question: "How can I prepare for the CUET exam?",
      answer: "ABASKO gives comprehensive study materials with mock tests and online coaching to students for preparation for CUET. Please focus on the main subjects, go through previous papers, and use our mock exams."
    },
    {
      question: "Does ABASKO conduct CUET mock tests?",
      answer: "Yes, ABASKO does conduct a CUET mock test to enable you to get accustomed to the format of the examination, put up a good show by managing time well in attempting all questions, and assess your level of preparation."
    },
    {
      question: "Which colleges take CUET scores?",
      answer: "More than 200 universities accept the marks secured in the CUET, amongst which some of these CUET colleges are ranked amongst the top central universities of Delhi University, Jawaharlal Nehru University, and Banaras Hindu University."
    },
  ];

  const technicalFAQs = [
    {
      question: "How can I register for CUET online coaching with ABASKO?",
      answer: "You can register for CUET online coaching with ABASKO on our website, choose the course CUET and make the payment. You will get access to study materials, live classes, and mock tests."
    },
    {
      question: "What are the best CUET study materials provided by ABASKO?",
      answer: "ABASKO provides chiffon-screened study materials. The following outline elaborates on this in detail: Textbooks, Video lectures, Practice questions, and Revision notes. These study materials represent the full syllabus of CUET."
    },
    {
      question: "What if I have some problems with access to online classes?",
      answer: "Cannot access our online classes through our website because of technical issues? Check your internet settings and browser settings. If that doesn't work, you can reach out to us through the help section on our website."
    },
    {
      question: "Are the classes recorded for later viewing?",
      answer: "Yes, all our live classes are recorded and available within your ABASKO account. You can watch them at your convenience in order to revise concepts."
    },
    {
      question: "How do I reset my ABASKO account password?",
      answer: "If you have forgotten your password, simply click 'Forgot Password' from the login page and follow the prompts to reset your password via email."
    },
  ];

  return (
    <>
      <section style={{ display: "flex", justifyContent: "center" }}>
        <section className='FAQ'>
          <h1>CUET FAQ's</h1>
          {cuetFAQs.map((faq, index) => (
            <div className="container" key={index}>
              <h4>{faq.question}</h4>
              <p>{faq.answer}</p>
            </div>
          ))}
        </section>
      </section>

      <section style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
        <section className='FAQ'>
          <h1>Technical FAQ's</h1>
          {technicalFAQs.map((faq, index) => (
            <div className="container" key={index}>
              <h4>{faq.question}</h4>
              <p>{faq.answer}</p>
            </div>
          ))}
        </section>
      </section>
    </>
  );
}

export default OurCources;