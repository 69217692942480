import React from 'react'
import Layout from '../components/Layout/Layout';
import "../styles/MernProgram.css";
import { Grid } from '@mui/material';
import { IoCheckmark } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import { useNavigate } from 'react-router-dom';

const MernProgram = () => {

  const navigate = useNavigate();

  const handleClick=()=>{
    navigate("/getAdmission")
  }

  return (
    <Layout>
      <div className="mern_program_upper_banner">
      </div>
      <div className="mern_program_lower_section">
        <Grid container className='card_container'>
          <Grid item md={5.7} sm={12} className='card_wrapper'>
            <div className="content_card">
              <div className="heading_box">
                <h4>MERN </h4>
                <h5>FULLSTACK DEVELOPMENT</h5>
              </div>
              <div className="plan_box">
                <h5>Basic Plan</h5>
                <div className="pragraph_box">
                  <span className='check_icon_box'><IoCheckmark className='check_icon' /></span>
                  <p>Online mode & Access to comprehensive course materials</p>
                </div>
                <div className="pragraph_box">
                  <span className='check_icon_box'><IoCheckmark className='check_icon' /></span>
                  <p>Hands-on projects and assignments</p>
                </div>
                <div className="pragraph_box">
                  <span className='check_icon_box'><IoCheckmark className='check_icon' /></span>
                  <p>Placement assistance up to  6-7 drives</p>
                </div>
                <div className="pragraph_box">
                  <span className='check_icon_box'><IoCheckmark className='check_icon' /></span>
                  <p>HCertificate of experience from the company</p>
                </div>
                <div className="pragraph_box">
                  <span className='cross_icon_box'><RxCross1 className='cross_icon' /></span>
                  <p style={{ textDecoration: 'line-through', textDecorationColor: '#4DA863' }}>1:1 Mentorship</p>
                </div>
                <div className="pragraph_box">
                  <span className='cross_icon_box'><RxCross1 className='cross_icon' /></span>
                  <p style={{ textDecoration: 'line-through', textDecorationColor: '#4DA863' }}>Unlimited drives and Money back plan</p>
                </div>
              </div>

              <div className="choose_plan_btn_box" onClick={handleClick}>
                <button>Choose plan</button>
              </div>

              <div className="card_price_box">
                <span>Rs 15999</span></div>
            </div>
          </Grid>

          <Grid item md={5.7} sm={12} className='card_wrapper'>
            <div className="content_card">
              <div className="heading_box">
                <h4>MERN</h4>
                <h5>FULL-STACK DEVELOPMENT</h5>
              </div>
              <div className="plan_box">
                <h5>Premium Plan</h5>
                <div className="pragraph_box">
                  <span className='check_icon_box'><IoCheckmark className='check_icon' /></span>
                  <p>Online mode & Access to comprehensive course materials</p>
                </div>
                <div className="pragraph_box">
                  <span className='check_icon_box'><IoCheckmark className='check_icon' /></span>
                  <p>Hands-on projects and assignments</p>
                </div>
                <div className="pragraph_box">
                  <span className='check_icon_box'><IoCheckmark className='check_icon' /></span>
                  <p>Placement assistance up to  6-7 drives</p>
                </div>
                <div className="pragraph_box">
                  <span className='check_icon_box'><IoCheckmark className='check_icon' /></span>
                  <p>HCertificate of experience from the company</p>
                </div>
                <div className="pragraph_box">
                  <span className='check_icon_box'><IoCheckmark className='check_icon' /></span>
                  <p>1:1 Mentorship</p>
                </div>
                <div className="pragraph_box">
                  <span className='check_icon_box'><IoCheckmark className='check_icon' /></span>
                  <p>Unlimited drives and Money back plan</p>
                </div>
              </div>

              <div className="choose_plan_btn_box" onClick={handleClick}>
                <button>Choose plan</button>
              </div>

              <div className="card_price_box">
                <span>Rs 15999</span>
              </div>
        
            </div>
          </Grid>
        </Grid>

        <div className="program_pargraph_section">
          <p>To get the most out of your plan, actively engage with course materials and hands-on projects. Utilize available resources like, recorded sessions, and community forums to enhance your learning and career opportunities.</p>
        </div>
      </div>
    </Layout>
  )
}

export default MernProgram;
