/** @format */

import React from "react";
import "../styles/NewBlogs.css";
import Layout from "../components/Layout/Layout";
import blogsimage1 from "../assets/blogs1.png";
import blogsimage2 from "../assets/blogs2.png";
import blogsimage3 from "../assets/blogs3.png";
import blogsimage4 from "../assets/blogs4.png";
import blogsimage5 from "../assets/blogs5.png";
import blogsimage6 from "../assets/blog_img_1.png";
import imagelogo from "../assets/imagelogoblogs.png";

const NewBlogsPage = () => {
  return (
    <>
      <Layout>
        <div className="blogs-page-container">
          <div className="blogs-page-text">
            <h1 className="Essential-textblog">
              5 Essential Tips for JEE/NEET Aspirants
            </h1>
            <p className="tocaetext">to Ace Their Exams</p>
          </div>
        </div>
        <div id="blogs-downdiv"></div>
        <div id="blogs-imagecontainer">
          <div>
            <div id="blogs-imagetop">
              <img src={imagelogo} className="name-blogsimage" />
              <h3 id="Realistic-text">Create a Realistic Study Plan</h3>
            </div>
            <div id="blogs-leftul">
              <p id="leftul-blogspara">
                A well-structured study plan is the foundation of effective
                preparation. Break down the syllabus into
              </p>
              <p id="leftul-blogspara">
                manageable sections and assign time slots to each subject. Make
                sure your plan:
              </p>
              <ul id="Study-blogstextul">
                <li id="Study-blogstextli">Balances all subjects equally</li>
                <li id="Study-blogstextli">Allows time for revision</li>
                <li id="Study-blogstextli">
                  Includes short breaks to avoid burnout
                </li>
              </ul>
            </div>
          </div>

          <div>
            <img src={blogsimage5} id="blog-sideimage" />
          </div>
        </div>
        <div id="Clarity-mainbody">
          <div>
            <img src={blogsimage4} id="Clarity-images" />
          </div>
          <div id="Clarity-sidemain">
            <div id="Clarity-sidetopone">
              <p id="Clarity-sidetoponetext">Focus on Conceptual Clarity</p>
              <img src={imagelogo} className="name-blogsimage" />
            </div>
            <div id="Clarity-sidedown">
              <p id="Clarity-paraone">
                A well-structured study plan is the foundation of effective
                preparation. Break down the syllabus into manageable sections
                and assign time slots to each subject. Make sure your plan:
              </p>
            </div>
            <div className="Clarity-lastone">
              <ul id="Clarity-lastoneul">
                <li id="Clarity-lastoneli">Balances all subjects equally</li>
                <li id="Clarity-lastoneli">Allows time for revision</li>
                <li id="Clarity-lastoneli">
                  Includes short breaks to avoid burnout
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/*  */}
        <div id="blogs-imagecontainer">
          <div>
            <div id="blogs-imagetop">
              <img src={imagelogo} className="name-blogsimage" />
              <h3 id="Realistic-text">Practice, Practice, Practice</h3>
            </div>
            <div id="blogs-leftul">
              <p id="leftul-blogspara">
                A well-structured study plan is the foundation of effective
                preparation. Break down the syllabus into
              </p>
              <p id="leftul-blogspara">
                manageable sections and assign time slots to each subject. Make
                sure your plan:
              </p>
              <ul id="Study-blogstextul">
                <li id="Study-blogstextli">Balances all subjects equally</li>
                <li id="Study-blogstextli">Allows time for revision</li>
                <li id="Study-blogstextli">
                  Includes short breaks to avoid burnout
                </li>
              </ul>
            </div>
          </div>

          <div>
            <img src={blogsimage3} id="blog-sideimage" />
          </div>
        </div>
        {/* 4th */}
        <div id="Clarity-mainbody">
          <div>
            <img src={blogsimage2} id="Clarity-images" />
          </div>
          <div id="Clarity-sidemain">
            <div id="Clarity-sidetopone">
              <p id="Clarity-sidetoponetext">Stay Consistent and Disciplined</p>
              <img src={imagelogo} className="name-blogsimage" />
            </div>
            <div id="Clarity-sidedown">
              <p id="Clarity-paraone">
                A well-structured study plan is the foundation of effective
                preparation. Break down the syllabus into manageable sections
                and assign time slots to each subject. Make sure your plan:
              </p>
            </div>
            <div className="Clarity-lastone">
              <ul id="Clarity-lastoneul">
                <li id="Clarity-lastoneli">Balances all subjects equally</li>
                <li id="Clarity-lastoneli">Allows time for revision</li>
                <li id="Clarity-lastoneli">
                  Includes short breaks to avoid burnout
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* 5th */}
        <div id="blogs-imagecontainer">
          <div>
            <div id="blogs-imagetop">
              <img src={imagelogo} className="name-blogsimage" />
              <h3 id="Realistic-text">Take Care of Your Health</h3>
            </div>
            <div id="blogs-leftul">
              <p id="leftul-blogspara">
                A well-structured study plan is the foundation of effective
                preparation. Break down the syllabus into
              </p>
              <p id="leftul-blogspara">
                manageable sections and assign time slots to each subject. Make
                sure your plan:
              </p>
              <ul id="Study-blogstextul">
                <li id="Study-blogstextli">Balances all subjects equally</li>
                <li id="Study-blogstextli">Allows time for revision</li>
                <li id="Study-blogstextli">
                  Includes short breaks to avoid burnout
                </li>
              </ul>
            </div>
          </div>

          <div>
            <img src={blogsimage1} id="blog-sideimage" />
          </div>
        </div>
      </Layout>
    </>
  );
};
export default NewBlogsPage;
