import React from 'react';
import drop_side_line from "../../assets/drop_side_line.png";
import "./JobLeadingCourse.css";
import Grid from '@mui/material/Grid';
// import Grid from '@mui/material/Unstable_Grid2';
import duration_icon from "../../assets/duration_icon.png";
import time_icon from "../../assets/time_icon.png";
import { Button } from '@mui/material';
import { GoDownload } from "react-icons/go";
import { leadingCourses } from "./JobLeadingCourseData.js";
import { Link, useNavigate } from 'react-router-dom';
import Openbook from "../../assets/openbook.png";

const JobLeadingCourse = () => {

  const navigate = useNavigate();

  const handleViewModules = () => {
    navigate("/course_module");
  }

  const handleViewMore = () => {
    navigate("/courses");
  }

  return (
    <>
      <section className='leading_course' id='#courses'>

        <div className="header_box">
          <div className="jobleading_content_header">
            <h5>
              <span> Exam Categories</span>
            </h5>
          </div>
        </div>

        <Grid container className="card_wrapper">
          {leadingCourses?.map((item, i) => (
            <Grid item md={3.5} sm={5.5} xs={11} key={i}>
              <div className="course_card">
                <div className="course_content_data">
                  <h4>{item.head}</h4>
                  <div className='price'>
                    {/* <h3>Fee : 600</h3> */}
                    <button>Buy</button>
                  </div>
                </div>
                <div className="course_content_pic">
                  <img src={Openbook} alt="" />
                </div>
              </div>
            </Grid>
          ))}
        </Grid>

        {/* <div className="view_more_btn_box" onClick={handleViewMore}>
          <button>View More</button>
        </div> */}
        <br /><br /><br />
      </section>
    </>
  );
}

export default JobLeadingCourse;
