import React, { useState } from 'react';
import Layout from '../components/Layout/Layout';
import "../styles/CourseModule.css";
import { Grid } from '@mui/material';
import StarRateIcon from '@mui/icons-material/StarRate';
import module_enrolled_icon from "../assets/module_enrolled_icon.png";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import module_rocket from "../assets/module_rocket.png";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import open_book_icon from "../assets/book_img_icon.png";

import { moduleCourseData } from '../Data/ModuleCourseData';
import { useNavigate } from 'react-router-dom';

const CourseModule = () => {

  const [openIndex, setOpenIndex] = useState(null); // To track which module is open

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle accordion based on the index
  };

  const navigate = useNavigate();

  const handleEnroll = () => {
    navigate("/mern_program");
  };

  const handleViewCourse = () => {
    navigate("/courses");
  };

  return (
    <Layout>
      <section className='course_module_section'>
        <div className="header_module_box_wrapper">
          <Grid container className='header_module_container'>
            <Grid item xs={12} sm={3} className='header_left_module'>
              <h2>MERN</h2>
              <h3>STACK</h3>
            </Grid>
            <Grid item xs={12} sm={9} className='header_right_module'>
              <h4>Mern Stack Course for Beginners With Certification: Mastering the Essentials</h4>

              <div className="review_box">
                <div className="rating_box">
                  <span className='modul_rating_icon'><StarRateIcon /></span>
                  <span className='module_rating_number'>4.5</span>
                </div>

                <div className="module_enrolled_box">
                  <img src={module_enrolled_icon} alt="module_enrolled_icon" />
                  <span>Enrolled: 127</span>
                </div>
              </div>

              <div className="module_enrol_btn_box">
                <button className='enrol_btn_1' onClick={handleEnroll}>Enrol Now</button>
                <button className='enrol_btn_2' onClick={handleViewCourse}>View all courses
                  <ArrowRightAltIcon className='btn2_arrow_icon' />
                </button>
              </div>
            </Grid>
          </Grid>
        </div>

        {/* --- Module List---- */}

        {
          moduleCourseData?.map((item, i) => (
            <div className="list_box_wrapper" key={i}>
              <div className={`module_list ${openIndex === i ? 'active' : ''}`} onClick={() => toggleAccordion(i)}>
                <div className="left_module_box">
                  <div className="rocket_box">
                    <img src={module_rocket} alt="module_rocket" />
                  </div>

                  <div className="left_module_box_content">
                    <h4>MODULE {item.moduleNumber}</h4>
                    <h5>{item.moduleName}</h5>
                  </div>
                </div>

                <div className="right_module_box">
                  <TimerOutlinedIcon className='duration_icon' />
                  <p>{item.duration}</p>
                  {openIndex === i ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
              </div>

              {openIndex === i && (
                <div className="accordion_content">
                  <ul>
                    {
                      item.listData.map((data, j) => (
                        <li className='list_box' key={j}>
                          <div className="list_left">
                            <img src={open_book_icon} alt="open_book_icon" />
                            <span>{data.list}</span>
                          </div>
                          <div className="left_right">
                            <h2>{data.timing}</h2>
                          </div>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              )}
            </div>
          ))
        }
      </section>
    </Layout>
  );
};

export default CourseModule;
