import React from 'react';
import Layout from '../components/Layout/Layout';
import "../styles/Enquire.css";
import enquire_right_img from "../assets/enquire_right_logo.png";
import { IoLocationSharp } from "react-icons/io5";
import { FaPhone } from "react-icons/fa6";
import { MdMail } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import hex_img from "../assets/hexa_img_enquire.png";

const Enquire = () => {

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/submitEnquire');
  };

  return (
    <Layout>
      <section className='enquire_section'>
        <div className="enq_box">
          <div className="left_enq_box">
            <h4>Let’s Talk</h4>
            <p>To request a quote or arrange a meeting, contact us directly or fill out the form, and we will get back to you promptly.</p>

            <form action="" className='form_box'>
              <div className='input_box'>
                <label htmlFor="">Your Name</label>
                <input type="text" />
              </div>

              <div className='midlle_input_box'>
                <div className='sub_input_box'>
                  <label htmlFor="">Phone Number</label>
                  <input type="number" />
                </div>

                <div className='sub_input_box'>
                  <label htmlFor="">E-mail</label>
                  <input type="email" />
                </div>
              </div>

              <div className="input_box">
                <label htmlFor="">Your Message</label>
                <textarea name="" id="" placeholder='Type something if you want..' />
              </div>

              <div className="send_message_wrapper">
                <button onClick={handleNavigate}><Link to={"./submitEnquire"}>Send Message</Link></button>
              </div>

            </form>
          </div>


          <div className="right_enq_box">
            <img src={enquire_right_img} alt="enquire_right_img" />

            <div className="enq_add_box">
              <h5><IoLocationSharp className='location_icon' />BTM Layout, 2nd Stage, Banglore, Karnataka 560076</h5>
              <h5><FaPhone className='phone_icon' />
                +91 7672477256</h5>
              <h5><MdMail className='mail_icon' />academy@therhypernxt.com</h5>
            </div>
          </div>

          <img className='hex_img_enquire' src={hex_img} alt="hex_img" />
        </div>
      </section>
    </Layout>
  )
}

export default Enquire;
