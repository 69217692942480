/** @format */

import React from "react";
import "../styles/RefundPolicy.css";
import Layout from "../components/Layout/Layout";

const RefundPolicy = () => {
  return (
    <>
      <Layout>
        <div id="refundpolicymain">
          <div id="">
            <h2 id="refundpolicymain-text">Shipping Policy</h2>
          </div>
          <div id="refundpolicymain-paraone">
            <h3 className="refundpolicymain-h3">
              At Abasko Learning, we are committed to delivering a seamless experience for our customers. Below are the details of our shipping policies to ensure transparency and satisfaction in our service.
            </h3>
          </div>
          <div id="Eligibilitymain">
            <h3 id="Eligibilityheading">1. Shipping Process</h3>
            <p id="Eligibilityp">
              We strive to deliver all orders promptly and securely to your doorstep. Once your order is confirmed, you will receive an email or SMS notification with your order details, estimated delivery date, and tracking information.
            </p>
          </div>
          <div id="Eligibilitymain">
            <h3 id="Eligibilityheading">2. Shipping Charges</h3>
            <p id="Eligibilityp">
              Standard Shipping: We offer standard shipping across India, free of charge for all orders above ₹999.
            </p>
            <p id="Eligibilityp">
              For orders below ₹999, a nominal shipping fee of ₹50 will be charged.
            </p>
            <p id="Eligibilityp">
              Express Shipping: We also provide express shipping services for an additional charge, calculated based on the delivery location and urgency.
            </p>
          </div>
          <div id="Eligibilitymain">
            <h3 id="Eligibilityheading">3. Delivery Timeframes</h3>
            <ul id="Eligibilitymainul">
              <li id="Eligibilitymainli">
                Standard Delivery: Orders are typically delivered within 57 business days.
              </li>
              <li id="Eligibilitymainli">
                Express Delivery: Orders with express shipping are delivered within 24 business days.
              </li>
            </ul>
            <p id="Eligibilityp">
              Please note that delivery times may vary based on your location, availability of products, and public holidays.
            </p>
          </div>
          <div id="Eligibilitymain">
            <h3 id="Eligibilityheading">4. Shipping Locations</h3>
            <p id="Partialpara">
              Abasko Learning currently ships to all major cities and towns across India. We do not offer international shipping at this time.
            </p>
          </div>
          <div id="Eligibilitymain">
            <h3 id="Eligibilityheading">5. Order Tracking</h3>
            <p id="Partialpara">
              Once your order is shipped, you will receive a tracking number via email or SMS. You can use this tracking number on our website or the courier partner’s website to track your shipment in realtime.
            </p>
          </div>
          <div id="Eligibilitymain">
            <h3 id="Eligibilityheading">6. Delays and Issues</h3>
            <p id="Partialpara">
              While we make every effort to ensure timely delivery, there may be occasional delays due to unforeseen circumstances, such as weather conditions, strikes, or highdemand periods. In such cases, our team will keep you informed and work to resolve the issue promptly.
            </p>
          </div>
          <div id="Eligibilitymain">
            <h3 id="Eligibilityheading">7. Incorrect Shipping Information</h3>
            <p id="Partialpara">
              Please ensure that your shipping details are accurate at the time of placing the order. Abasko Learning will not be responsible for any delays or delivery failures resulting from incorrect or incomplete information.
            </p>
          </div>
          <div id="Eligibilitymain">
            <h3 id="Eligibilityheading">8. Damaged or Lost Shipments</h3>
            <p id="Partialpara">
              If your order arrives damaged or is lost in transit, please contact us immediately at [support@abaskolearning.com](mailto:support@abaskolearning.com) or call our customer service at 8685911755 We will assist you in getting a replacement or refund.
            </p>
          </div>
          <div id="Eligibilitymain">
            <h3 id="Eligibilityheading">9. Changes to Shipping Policy</h3>
            <p id="Partialpara">
              Abasko Learning currently ships to all major cities and towns across India. We do not offer international shipping at this time.Abasko Learning reserves the right to modify this shipping policy at any time. Any changes will be posted on our website and will apply to future orders.
            </p>
            <p id="Partialpara">
            For any further assistance regarding shipping or delivery, please reach out to our customer support team. We are here to help!
            </p>
          </div>
          <div id="privacy-startmain">
            <h3 id="Collectheading">Contact Us</h3>
            <p id="Securitypara">
              If you have any questions about this Privacy Policy or how we
              handle your information, please contact us at:
            </p>
            <p id="Securitypara"></p>
            <p id="Securitypara">Abasko Learning (Abaskeo Solution Pvt Ltd)  <br />Email: <a href="mailto:abasko@learning.com">abasko@learning.com</a><br />Phone: <a href="tel:918685911755">8685911755</a></p>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default RefundPolicy;
