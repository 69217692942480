import React from 'react'
import Layout from '../components/Layout/Layout';
import "../styles/ApplicationForm.css";
import logo from "../assets/hypernxt_logo.png";
import { Grid } from '@mui/material';
import { FaChevronLeft } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const ApplicationForm = () => {
  return (
    <Layout>
      <section className='application_from_section'>
        <div className="application_form_upper_box">
          <div className="content_box">
            <Link to="/careers">
              <FaChevronLeft className='application_form_back_icon' />
            </Link>
            <img src={logo} alt="logo" />
            <h5>Academy</h5>
          </div>
        </div>
        <div className="application_form_box_wrapper">
          <h4>Application Form</h4>


          <form action="" className="application_form_box">

            <div className="application_box_1">
              <h4><span style={{ color: 'red' }}>* </span> Job Profile</h4>
              <p>Please Select the opening you are applying for</p>
              <Grid container className='application_box_container'>
                <Grid item sm={9.6} xs={8} className='application_outer_select_box'>
                  <select name="" id="">
                    <option value="">Java Expert</option>
                    <option value="">Mern Expert</option>
                    <option value="">Mean Expert</option>
                  </select>
                </Grid>
                <Grid item sm={1.6} xs={2.5} className='application_inner_view_box'>
                  <button>View</button>
                </Grid>
              </Grid>
            </div>

            <div className='divider'></div>

            <div className="application_box_2">
              <h4>Personal Details</h4>
              <p>* Please enter the first name, last name, D.O.B according to your Aadhar Card</p>
              <Grid container className='application_box_container'>
                <Grid item sm={3.5} xs={12} className='app_input_box'>
                  <input type="text" placeholder='*  HNA ID' />
                </Grid>
                <Grid item sm={3.5} xs={12} className='app_input_box'>
                  <input type="text" placeholder='*  First Name' />
                </Grid>
                <Grid item sm={3.5} xs={12} className='app_input_box'>
                  <input type="text" placeholder='*  Last Name' />
                </Grid>
              </Grid>
            </div>

            <div className="application_box_3">
              <Grid container className='application_box_container'>
                <Grid item sm={6.5} xs={12} className='application_outer_select_box'>
                  <select name="" id="">
                    <option value="">*  Gender</option>
                    <option value="">Male</option>
                    <option value="">Female</option>
                    <option value="">Other</option>

                  </select>
                </Grid>
                <Grid item sm={5} xs={12} className='app_input_box'>
                  <input type="date" placeholder='*  D.O.B' />
                </Grid>
              </Grid>
            </div>

            <div className="application_box_4">
              <Grid container className='application_box_container'>
                <Grid item sm={6.5} xs={12} className='app_input_box'>
                  <input type="email" placeholder='*  E-Mail' />
                </Grid>
                <Grid item sm={5} xs={12} className='app_input_box'>
                  <input type="text" placeholder='Work Email (if Any)' />
                </Grid>
              </Grid>
            </div>

            <div className="application_box_5">
              <Grid container className='application_box_container'>
                <Grid item sm={6.5} xs={12} className='app_input_box'>
                  <input type="number" placeholder='*  10 digit phone number' />
                </Grid>
                <Grid item sm={5} xs={12} className='app_input_box'>
                  <input type="number" placeholder='Emergency contact number' />
                </Grid>
              </Grid>
            </div>

            <div className="application_box_6">
              <Grid container className='application_box_container'>
                <Grid item xs={12} className='app_input_box'>
                  <textarea name="" id="" placeholder='* Permanent Address (House/ Flat #, Building/Apartment Name, Area, Colony) As per Aadhar Card' />
                </Grid>
              </Grid>
            </div>

            <div className="application_box_7">
              <Grid container className='application_box_container'>
                <Grid item sm={5.5} xs={12} className='app_input_box'>
                  <input type="text" placeholder='*  City' />
                </Grid>

                <Grid item sm={5.5} xs={12} className='application_outer_select_box'>
                  <select name="" id="">
                    <option value="">*  State</option>
                    <option value="">Odisha</option>
                    <option value="">Karnatak</option>
                    <option value="">Maharashtra</option>

                  </select>
                </Grid>
              </Grid>
            </div>

            <div className="application_box_8">
              <Grid container className='application_box_container'>
                <Grid item sm={5.5} xs={12} className='app_input_box'>
                  <input type="text" placeholder='*  Pincode' />
                </Grid>

                <Grid item sm={5.5} xs={12} className='application_outer_select_box'>
                  <select name="" id="">
                    <option value="">*  Nationality</option>
                    <option value="">Indian</option>
                    <option value="">American</option>
                    <option value="">Japanese</option>

                  </select>
                </Grid>
              </Grid>
            </div>

            <div className="application_box_8">
              <p><span style={{ color: 'red' }}>* </span> Resume (Upload file in pdf format, max upload limit: 5mb)</p>

              <input type="file" />
            </div>

            <div className="application_box_9">
              <p><span style={{ color: 'red' }}>* </span>Photograph (Upload file in .png, .jpeg or .jpg format, max upload limit: 2mb)</p>

              <input type="file" />
            </div>

            <div className="application_box_10">
              <h4>Previous Work Experience</h4>
              <p>Do you have any prior Work Experience?</p>

              <label>
                <input type="radio" name="option" value="option1" />
                Yes
              </label>
              <label>
                <input type="radio" name="option" value="option2" />
                No
              </label>
            </div>

            <div className="application_box_11">
              <p><span style={{ color: 'red' }}>* </span>Are you a Fresher?</p>

              <label>
                <input type="radio" name="option" value="option1" />
                Yes
              </label>
              <label>
                <input type="radio" name="option" value="option2" />
                No
              </label>
            </div>

            <div className="application_box_12">
              <h4>Education Details</h4>
              <p><span style={{ color: 'red' }}>* </span>Graduation</p>

              <Grid container className='application_box_container'>
                <Grid item sm={5.7} xs={12} className='app_input_box'>
                  <input type="text" placeholder='*  University Name' />
                </Grid>
                <Grid item sm={5.7} xs={12} className='app_input_box'>
                  <input type="text" placeholder='*  Institution Name' />
                </Grid>
              </Grid>

              <Grid container className='application_box_container'>

                <Grid item sm={3.5} xs={12} className='application_outer_select_box'>
                  <select name="" id="">
                    <option value="">*  Degree</option>
                    <option value="">Graduation</option>
                    <option value="">Post Graduation</option>
                    <option value="">BCA</option>
                    <option value="">MCA</option>
                  </select>
                </Grid>

                <Grid item sm={3.5} xs={12} className='application_outer_select_box'>
                  <select name="" id="">
                    <option value="">*  Specialization</option>
                    <option value="">Computer Science</option>
                  </select>
                </Grid>

                <Grid item sm={3.5} xs={12} className='app_input_box'>
                  <input type="text" placeholder='*  Percentage/CGPA' />
                </Grid>

              </Grid>

              <Grid container className='application_box_container'>
                <Grid item sm={5.7} xs={12} className='app_input_box'>
                  <input type="text" placeholder='*  Year of Passing (eg. 2022)' />
                </Grid>
                <Grid item sm={5.7} xs={12} className='app_input_box'>
                  <input type="text" placeholder='*  Current Active Backlogs' />
                </Grid>
              </Grid>
            </div>

            <div className="application_box_11">
              <p><span style={{ color: 'red' }}>* </span> Have you completed your final year/semester exam (including Project Viva)?</p>

              <label>
                <input type="radio" name="option" value="option1" />
                Yes
              </label>
              <label>
                <input type="radio" name="option" value="option2" />
                No
              </label>
            </div>

            <div className="application_box_14">
              <h4><span style={{ color: 'red' }}>* </span> Tech Stack</h4>
              <p>Please Select your Primary Area of Expertise</p>

              <Grid container className='application_box_container'>
                <Grid xs={12} className='application_outer_select_box'>
                  <select name="" id="">
                    <option value="">*  Primary Area of Expertise</option>
                    <option value="">MERN</option>
                    <option value="">MEAN Graduation</option>
                    <option value="">JAV</option>
                  </select>
                </Grid>
              </Grid>
            </div>

            <div className="application_box_14">
              <h4>Skill Sets</h4>
              <p>Please mention your skill sets separated by commas</p>

              <Grid container className='application_box_container'>
                <Grid xs={12} className='app_input_box'>
                  <input type="text" placeholder='Skill sets' />
                </Grid>
              </Grid>
            </div>

            <div className="application_box_14">
              <h4>Technical Languages Familiar with</h4>
              <p>Please mention the languages separated by commas</p>

              <Grid container className='application_box_container'>
                <Grid xs={12} className='app_input_box'>
                  <input type="text" placeholder='Technical Languages' />
                </Grid>
              </Grid>
            </div>

            <div className="application_box_14">
              <h4>Certifications</h4>
              <p>Please mention your certifications separated by commas</p>

              <Grid container className='application_box_container'>
                <Grid xs={12} className='app_input_box'>
                  <input type="text" placeholder='Certifications' />
                </Grid>
              </Grid>
            </div>

            <div className="application_box_14">
              <h4>Project Details</h4>
              <p>Please mention your certifications separated by commas</p>

              <Grid container className='application_box_container'>
                <Grid xs={12} className='app_input_box'>
                  <input type="text" placeholder='Please explain any recent projects' />
                </Grid>
              </Grid>
            </div>

            <div className="chek_box_container">
              <label>
                <input type="checkbox" name="preference" value="preference1" className='applcation_checkbox' />
                I have read and accepted the <span>Privacy Note</span> and <span>Terms of Use</span>
              </label>
            </div>

            <div className="application_submit_btn">
              <button>Submit</button>
            </div>

          </form>



        </div>
      </section>
    </Layout>
  )
}

export default ApplicationForm
