import React from 'react';
import Layout from "../components/Layout/Layout.jsx";
import "../styles/Courses.css";
import { Grid } from '@mui/material';
import { FaAngleRight } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import { leadingCourses } from "../Data/CoursesData.js";
import { useNavigate } from 'react-router-dom';
import FAQ from '../components/FAQ/FAQ.jsx';
import Frame from '../assets/Frame.png'
import JobLeadingCourse from '../components/JobLeadingCourse/JobLeadingCourse.jsx';


const Courses = () => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/course_module")
  }

  const handleCourseEnroll = () => {
    navigate("/mern_program")
  }

  return (
    <>
      <Layout>
        <section className='courses_section' style={{minHeight: "fit-content"}}>
          <div className="header_box">
           <div className="course-heading">
            <h5>Explore Categories / IIT JEE</h5>
            <p>Abasko Learning provides the best online coaching for IIT JEE preparation, with expert faculty, live and recorded classes, and in-depth study materials. We offer courses for Class 11, Class 12, and repeaters, all at affordable fees. Enroll with Abasko Learning today and gear up for IIT JEE success!</p>
            <img src={Frame} alt="" className='frame' />
            </div> 
          </div>

        </section>
        <JobLeadingCourse />
        <div className="faqqq" style={{marginTop: "-150px"}}>
          <FAQ />
          </div>
      </Layout>
    </>
  )
}

export default Courses;
