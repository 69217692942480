import React from 'react';
import Layout from '../components/Layout/Layout';
import "../styles/About.css";
import ossilate_upper_banner from "../assets/oooscillate_about_up_banner.png";
import lower_about_banner from "../assets/lower_about_banner.png";
import OurMotto from '../components/OurMotto/OurMotto';
import OurTeam from '../components/OurTeam/OurTeam';
import CompaniesMarquee from '../components/CompaniesMarquee/CompaniesMarquee';

const About = () => {
  return (
    <Layout>
      <div class="we-are-block">
        <div id="about-us-section">
          <div class="about-us-image">
            <img src="https://digitalupgrade.com/images/lobbyimage_1.jpg" width="808" height="458" alt="Lobby Image" />
          </div>
          <div class="about-us-info">
            <h2>Our Mission</h2>
            <p>To revolutionize the learning experience by providing a user-friendly and comprehensive platform that empowers educators to deliver high-quality education and enables learners to achieve their fullest potential, anytime, anywhere.</p>
            {/* <a href="#" title="About Us Button">ABOUT US</a> */}
          </div>
        </div>
        <div id="history-section">
          <div class="history-image">
            <img src="https://digitalupgrade.com/images/building_pic.jpg" width="951" height="471" alt="Building Pic" />
          </div>
          <div class="history-info">
            <h2>Our Approach</h2>
            <p>We combine innovative technology with a learner-centered design, offering interactive tools, flexible learning paths, and real-time support. Our approach focuses on creating an engaging and personalized environment that fosters growth, collaboration, and continuous improvement.</p>
            {/* <a href="#" title="History Button">HISTORY</a> */}
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default About;
