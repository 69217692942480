import React from 'react'
import Layout from '../components/Layout/Layout';
import "../styles/NewsEvents.css";
import drop_side_line from "../assets/drop_side_line.png";
import { Grid } from '@mui/material';
import { newsEventData } from "../Data/NewsEventData.js";

const NewsEvents = () => {
  return (
    <Layout>
      <section className='news_event_section'>

        <div className="news_event_header_section">
          <h3>NEWS & EVENTS</h3>
        </div>

        <div className="news_event_card_section">

          <div className="header_box">
            <img src={drop_side_line} alt="drop_side_line" />

            <div className="news_event_content_header">
              <h5>
                <span>News & </span>
                <span> Events</span>
              </h5>

              <p>HyperNxt has been participating in various events & have been featured in media.</p>
            </div>
          </div>

          <Grid container className='news_event_card_container'>
            {
              newsEventData?.map((item, i) => (
                <Grid item sm={5.5} xs={12} className='news_event_card'>
                  <div className="imgBox">
                    <img src={item.img} alt="" />
                  </div>
                  <div className="new_event_lower_card_content">
                    <h2>Innovate 2024: Shaping the Future of Technology</h2>
                    <h4>Date:{item.date}</h4>
                    <h4>Venue:{item.venue}</h4>
                  </div>
                </Grid>
              ))
            }
          </Grid>
        </div>

      </section>
    </Layout>
  )
}

export default NewsEvents
