import news_event_card_img from "../assets/news_event_card_img.png";


export const newsEventData = [
  {
    img:news_event_card_img,
    date:"Sept 02, 2024",
    venue:"Venue : HyperNxt Technologies, BTM 2nd Stage, Banglore"
  },
  {
    img:news_event_card_img,
    date:"Sept 02, 2024",
    venue:"Venue : HyperNxt Technologies, BTM 2nd Stage, Banglore"
  },
  {
    img:news_event_card_img,
    date:"Sept 02, 2024",
    venue:"Venue : HyperNxt Technologies, BTM 2nd Stage, Banglore"
  },
  {
    img:news_event_card_img,
    date:"Sept 02, 2024",
    venue:"Venue : HyperNxt Technologies, BTM 2nd Stage, Banglore"
  },
  {
    img:news_event_card_img,
    date:"Sept 02, 2024",
    venue:"Venue : HyperNxt Technologies, BTM 2nd Stage, Banglore"
  },
  {
    img:news_event_card_img,
    date:"Sept 02, 2024",
    venue:"Venue : HyperNxt Technologies, BTM 2nd Stage, Banglore"
  },
]