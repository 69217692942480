import React from 'react';
import Layout from '../Layout/Layout';
import { Link, useNavigate } from 'react-router-dom';
import after_sumit_hex_img from "../../assets/after_submit_hexa_img.png";
import "./AfterSubmitEnquire.css";
import { GoChevronLeft } from "react-icons/go";

const AfterSubmitEnquire = () => {

  const navigate = useNavigate();

  const handleBackHome = () => {
    navigate("/")
  }

  const handleToAbout = () => {
    navigate("/about")
  }

  return (
    <Layout>
      <section className='after_inq_section'>
        <div className="enq_response_card">
          <div className='enq_response_card_left'>
            <h4><span>Your Response has been Recorded!</span></h4>
            <p>Thank You, We will reach out to you soon, check mails ans spam for updates.</p>

            <div className="afte_sum_enq_btn_box">
              <button onClick={handleBackHome} className='back_to_home_btn'><Link><GoChevronLeft />Back To Home</Link></button>
              <button onClick={handleToAbout} className='more_about_btn'><Link>More About Us</Link></button>
            </div>
          </div>

          <div className='enq_response_card_right'>
            <img src={after_sumit_hex_img} alt="after_sumit_hex_img" />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AfterSubmitEnquire;
