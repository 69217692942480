import React from "react";
import "./ourCources.css";
import CourcePoster from "../../assets/courcePoster.png";
import Star from "../../assets/Star 3.png";
import Enroll from "../../assets/image 57.png";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import logo1 from "../../../src/CoursesAssets/math.png";
import logo2 from "../../../src/CoursesAssets/math+ gt+eng.png";
import logo3 from "../../../src/CoursesAssets/math+ eng.png";
import logo4 from "../../../src/CoursesAssets/math+ gt.png";
import logo5 from "../../../src/CoursesAssets/physics.png";
import logo6 from "../../../src/CoursesAssets/physic+gt + eng.png";
import logo7 from "../../../src/CoursesAssets/physics + eng.png";
import logo8 from "../../../src/CoursesAssets/physics + gt.png";
import logo9 from "../../../src/CoursesAssets/economics.png";
import logo10 from "../../../src/CoursesAssets/economics +gt +eng.png";
import logo11 from "../../../src/CoursesAssets/economics+ eng.png";
import logo12 from "../../../src/CoursesAssets/economics +gt.png";
import logo13 from "../../../src/CoursesAssets/chemistry.png";
import logo14 from "../../../src/CoursesAssets/che+ gt+ eng.png";
import logo15 from "../../../src/CoursesAssets/che + eng.png";
import logo16 from "../../../src/CoursesAssets/che+ gt.png";
import logo17 from "../../../src/CoursesAssets/Pshchology.png";
import logo18 from "../../../src/CoursesAssets/psychology + GT + English.png";
import logo19 from "../../../src/CoursesAssets/psychology + eng.png";
import logo20 from "../../../src/CoursesAssets/gt.png";
import logo21 from "../../../src/CoursesAssets/eng.png";
import logo22 from "../../../src/CoursesAssets/eng + gt.png";
import logo23 from "../../../src/CoursesAssets/demo.png";

const OurCources = () => {
  return (
    <>
      <section className="ourCources">
        <h1>Our Courses</h1>
        <p>
          Explore a variety of courses designed to enhance your skills and
          knowledge.
        </p>
        <div className="cources">
          {/* card 1 */}
          <div className="cource">
            <a href="https://courses.abaskolearning.com/student/tests/view-package/67107d0569777f00c15f0c7a ">
              <img src={logo1} alt="" />
            </a>
            <h2 style={{ marginTop: "2%" }}>Mathematics </h2>
            {/* <div className="details">
              <img src={Star} alt="star" />
              <p>4.5</p>
              <p>|</p>
              <img src={Enroll} alt="enrolicon" />
              <p>Enrolled: 147</p>
            </div> */}
            <div className="price" style={{ marginTop: "3%" }}>
              <span>
                ₹3600 <div className="line"></div>
              </span>
              <span>₹3500</span>
            </div>
            <Button
              variant="outlined"
              href="https://courses.abaskolearning.com/student/tests/view-package/67107d0569777f00c15f0c7a "
              style={{ width: "100%", marginTop: "20px" }}
            >
              VIEW COURSE
            </Button>
          </div>
          {/* card 2 */}
          <div className="cource">
            <a href="https://courses.abaskolearning.com/student/tests/view-package/67107d4234039d18d778d70f">
              <img src={logo2} alt="" />
            </a>
            <h2 style={{ marginTop: "2%" }}>Mathematics + General Test + English </h2>
            <div className="price" style={{ marginTop: "3%" }}>
              <span>
                ₹4600 <div className="line"></div>
              </span>
              <span>₹4500 </span>
            </div>

            <Button
              variant="outlined"
              href="https://courses.abaskolearning.com/student/tests/view-package/67107d4234039d18d778d70f "
              style={{ width: "100%", marginTop: "20px" }}
            >
              VIEW COURSE
            </Button>
          </div>
          {/* card 3 */}
          {/* <div className="cource">
            <a href="https://courses.abaskolearning.com/student/tests/view-package/67107ef634039d18d778d714">
              <img src={logo3} alt="" />
            </a>
            <h2 style={{ marginTop: "2%" }}>Mathematics + English </h2> */}
            {/* <div className="details">
              <img src={Star} alt="star" />
              <p>4.5</p>
              <p>|</p>
              <img src={Enroll} alt="enrolicon" />
              <p>Enrolled: 147</p>
            </div> */}
            {/* <div className="price" style={{ marginTop: "3%" }}>
              <span>
                ₹4100 <div className="line"></div>
              </span>
              <span>₹4000</span>
            </div>
            <Button
              variant="outlined"
              href="https://courses.abaskolearning.com/student/tests/view-package/67107ef634039d18d778d714 "
              style={{ width: "100%", marginTop: "20px" }}
            >
              VIEW COURSE
            </Button>
          </div> */}
          {/* card 4 */}
          {/* <div className="cource">
            <a href="https://courses.abaskolearning.com/student/tests/view-package/67107f33787dbf28453024c6">
              <img src={logo4} alt="" />
            </a>
            <h2 style={{ marginTop: "2%" }}>Mathematics + General Test </h2> */}
            {/* <div className="details">
              <img src={Star} alt="star" />
              <p>4.5</p>
              <p>|</p>
              <img src={Enroll} alt="enrolicon" />
              <p>Enrolled: 147</p>
            </div> */}
            {/* <div className="price" style={{ marginTop: "3%" }}>
              <span>
                ₹4100 <div className="line"></div>
              </span>
              <span>₹4000 </span>
            </div>
            <Button
              variant="outlined"
              href="https://courses.abaskolearning.com/student/tests/view-package/67107f33787dbf28453024c6 "
              style={{ width: "100%", marginTop: "20px" }}
            >
              VIEW COURSE
            </Button>
          </div> */}
          {/* card 5 */}
          <div className="cource">
            <a href="https://courses.abaskolearning.com/student/tests/view-package/67107f7c2cde6c052b59a410 ">
              <img src={logo5} alt="" />
            </a>
            <h2 style={{ marginTop: "2%" }}>Physics </h2>
            {/* <h4>IIT-JEE, GATE Aspirants</h4> */}
            {/* <div className="details">
              <img src={Star} alt="star" />
              <p>4.5</p>
              <p>|</p>
              <img src={Enroll} alt="enrolicon" />
              <p>Enrolled: 147</p>
            </div> */}
            <div className="price" style={{ marginTop: "3%" }}>
              <span>
                ₹3600 <div className="line"></div>
              </span>
              <span>₹3500 </span>
            </div>
            <Button
              variant="outlined"
              href="https://courses.abaskolearning.com/student/tests/view-package/67107f7c2cde6c052b59a410 "
              style={{ width: "100%", marginTop: "20px" }}
            >
              VIEW COURSE
            </Button>
          </div>
          {/* card 6 */}
          <div className="cource">
            <a href="https://courses.abaskolearning.com/student/tests/view-package/67107ffe790f2375c6428fd9">
              <img src={logo6} alt="" />
            </a>
            <h2 style={{ marginTop: "2%" }}>Physics + General Test + English </h2>

            <div className="price" style={{ marginTop: "3%" }}>
              <span>
                ₹4600 <div className="line"></div>
              </span>
              <span>₹4500 </span>
            </div>
            <Button
              variant="outlined"
              href="https://courses.abaskolearning.com/student/tests/view-package/67107ffe790f2375c6428fd9 "
              style={{ width: "100%", marginTop: "20px" }}
            >
              VIEW COURSE
            </Button>
          </div>
          {/* card 7 */}
          {/* <div className="cource">
            <a href="https://courses.abaskolearning.com/student/tests/view-package/6710807d2cde6c052b59a417">
              <img src={logo7} alt="" />
            </a>
            <h2 style={{ marginTop: "2%" }}>Physics + English </h2> */}
            {/* <h4>IIT-JEE, GATE Aspirants</h4> */}
            {/* <div className="details">
              <img src={Star} alt="star" />
              <p>4.5</p>
              <p>|</p>
              <img src={Enroll} alt="enrolicon" />
              <p>Enrolled: 147</p>
            </div> */}
            {/* <div className="price" style={{ marginTop: "3%" }}>
              <span>
                ₹40000 <div className="line"></div>
              </span>
              <span>₹36000</span>
            </div>
            <Button
              variant="outlined"
              href="https://courses.abaskolearning.com/student/tests/view-package/6710807d2cde6c052b59a417  "
              style={{ width: "100%", marginTop: "20px" }}
            >
              VIEW COURSE
            </Button>
          </div> */}
          {/* card 8 */}
          {/* <div className="cource">
            <a href="https://courses.abaskolearning.com/student/tests/view-package/671080af34039d18d778d720">
              <img src={logo8} alt="" />
            </a>
            <h2 style={{ marginTop: "2%" }}>Physics + General Test</h2>

            <div className="price" style={{ marginTop: "3%" }}>
              <span>
                ₹4100 <div className="line"></div>
              </span>
              <span>₹4000</span>
            </div>
            <Button
              variant="outlined"
              href="https://courses.abaskolearning.com/student/tests/view-package/671080af34039d18d778d720"
              style={{ width: "100%", marginTop: "20px" }}
            >
              VIEW COURSE
            </Button>
          </div> */}
          {/* card 9 */}
          <div className="cource">
            <a href="https://courses.abaskolearning.com/student/tests/view-package/671080fbbaf6be05d624239e">
              <img src={logo9} alt="" />
            </a>
            <h2 style={{ marginTop: "2%" }}>Economics </h2>
            <div className="price" style={{ marginTop: "3%" }}>
              <span>
                ₹3600 <div className="line"></div>
              </span>
              <span>₹3500</span>
            </div>
            <Button
              variant="outlined"
              href="https://courses.abaskolearning.com/student/tests/view-package/671080fbbaf6be05d624239e "
              style={{ width: "100%", marginTop: "20px" }}
            >
              VIEW COURSE
            </Button>
          </div>
          {/* card 10 */}
          <div className="cource">
            <a href="https://courses.abaskolearning.com/student/tests/view-package/67108141f734b50f170533cf">
              <img src={logo10} alt="" />
            </a>
            <h2 style={{ marginTop: "2%" }}>Economics + General Test + English </h2>

            <div className="price" style={{ marginTop: "3%" }}>
              <span>
                ₹4600 <div className="line"></div>
              </span>
              <span>₹4500</span>
            </div>
            <Button
              variant="outlined"
              href="https://courses.abaskolearning.com/student/tests/view-package/67108141f734b50f170533cf"
              style={{ width: "100%", marginTop: "20px" }}
            >
              VIEW COURSE
            </Button>
          </div>
          {/* card 11 */}
          {/* <div className="cource">
            <a href="https://courses.abaskolearning.com/student/tests/view-package/67108189f734b50f170533d0">
              <img src={logo11} alt="" />
            </a>
            <h2 style={{ marginTop: "2%" }}>Economics + English </h2>
            <div className="price" style={{ marginTop: "3%" }}>
              <span>
                ₹40000 <div className="line"></div>
              </span>
              <span>₹36000</span>
            </div>
            <Button
              variant="outlined"
              href="https://courses.abaskolearning.com/student/tests/view-package/67108189f734b50f170533d0 "
              style={{ width: "100%", marginTop: "20px" }}
            >
              VIEW COURSE
            </Button>
          </div> */}
          {/* card 12 */}
          {/* <div className="cource">
            <a href="https://courses.abaskolearning.com/student/tests/view-package/671081c3790f2375c6428fe7 ">
              <img src={logo12} alt="" />
            </a>
            <h2 style={{ marginTop: "2%" }}>Economics + General Test </h2>
            <div className="price" style={{ marginTop: "3%" }}>
              <span>
                ₹4100 <div className="line"></div>
              </span>
              <span>₹4000</span>
            </div>
            <Button
              variant="outlined"
              href="https://courses.abaskolearning.com/student/tests/view-package/671081c3790f2375c6428fe7 "
              style={{ width: "100%", marginTop: "20px" }}
            >
              VIEW COURSE
            </Button>
          </div> */}
          {/* card 13 */}
          <div className="cource">
            <a href="https://courses.abaskolearning.com/student/tests/view-package/671081f2baf6be05d624239f ">
              <img src={logo13} alt="" />
            </a>
            <h2 style={{ marginTop: "2%" }}>Chemistry </h2>
            <div className="price" style={{ marginTop: "3%" }}>
              <span>
                ₹3600 <div className="line"></div>
              </span>
              <span>₹3500</span>
            </div>
            <Button
              variant="outlined"
              href="https://courses.abaskolearning.com/student/tests/view-package/671081f2baf6be05d624239f "
              style={{ width: "100%", marginTop: "20px" }}
            >
              VIEW COURSE
            </Button>
          </div>
          {/* card 14 */}
          <div className="cource">
            <a href="https://courses.abaskolearning.com/student/tests/view-package/6710822b34039d18d778d72a ">
              <img src={logo14} alt="" />
            </a>
            <h2 style={{ marginTop: "2%" }}>Chemistry + English + General Test </h2>
            <div className="price" style={{ marginTop: "3%" }}>
              <span>
                ₹4600 <div className="line"></div>
              </span>
              <span>₹4500 </span>
            </div>
            <Button
              variant="outlined"
              href="https://courses.abaskolearning.com/student/tests/view-package/6710822b34039d18d778d72a "
              style={{ width: "100%", marginTop: "20px" }}
            >
              VIEW COURSE
            </Button>
          </div>
          {/* card 15 */}
          {/* <div className="cource">
            <a href="https://courses.abaskolearning.com/student/tests/view-package/6710822b34039d18d778d72a ">
              <img src={logo15} alt="" />
            </a>
            <h2 style={{ marginTop: "2%" }}>Chemistry + English </h2>
            <div className="price" style={{ marginTop: "3%" }}>
              <span>
                ₹4200 <div className="line"></div>
              </span>
              <span>₹4000</span>
            </div>
            <Button
              variant="outlined"
              href="https://courses.abaskolearning.com/student/tests/view-package/6710825bbaf6be05d62423a0  "
              style={{ width: "100%", marginTop: "20px" }}
            >
              VIEW COURSE
            </Button>
          </div> */}
          {/* card 16 */}
          {/* <div className="cource">
            <a href="https://courses.abaskolearning.com/student/tests/view-package/6710828ef734b50f170533d1 ">
              <img src={logo16} alt="" />
            </a>
            <h2 style={{ marginTop: "2%" }}>Chemistry + General Test </h2>
            <div className="price" style={{ marginTop: "3%" }}>
              <span>
                ₹4300 <div className="line"></div>
              </span>
              <span>₹4000</span>
            </div>
            <Button
              variant="outlined"
              href="https://courses.abaskolearning.com/student/tests/view-package/6710828ef734b50f170533d1 "
              style={{ width: "100%", marginTop: "20px" }}
            >
              VIEW COURSE
            </Button>
          </div> */}
          {/* card 17 */}
          <div className="cource">
            <a href="https://courses.abaskolearning.com/student/tests/view-package/671082be2cde6c052b59a41d">
              <img src={logo17} alt="" />
            </a>
            <h2 style={{ marginTop: "2%" }}> Psychology </h2>
            <div className="price" style={{ marginTop: "3%" }}>
              <span>
                ₹3800<div className="line"></div>
              </span>
              <span>₹3500</span>
            </div>
            <Button
              variant="outlined"
              href="https://courses.abaskolearning.com/student/tests/view-package/671082be2cde6c052b59a41d  "
              style={{ width: "100%", marginTop: "20px" }}
            >
              VIEW COURSE
            </Button>
          </div>
          {/* card 18 */}
          <div className="cource">
            <a href="https://courses.abaskolearning.com/student/tests/view-package/671082f1a9cdc47bd532064c">
              <img src={logo18} alt="" />
            </a>
            <h2 style={{marginTop:"2%"}}> Psychology + General Test + English </h2>
            <div className="price" style={{marginTop:"3%"}}>
              <span>
                ₹4800<div className="line"></div>
              </span>
              <span>₹4500</span>
            </div>
            <Button
              variant="outlined"
              href="https://courses.abaskolearning.com/student/tests/view-package/671082f1a9cdc47bd532064c "
              style={{ width: "100%", marginTop: "20px" }}
            >
              VIEW COURSE
            </Button>
          </div>
          {/* card 19 */}
          {/* <div className="cource">
            <a href="https://courses.abaskolearning.com/student/tests/view-package/67108329787dbf28453024d4">
              <img src={logo19} alt="" />
            </a>
            <h2 style={{marginTop:"2%"}}> Psychology + English </h2>
            <div className="price" style={{marginTop:"3%"}}>
              <span>
                ₹4300<div className="line"></div>
              </span>
              <span>₹4000</span>
            </div>
            <Button
              variant="outlined"
              href="https://courses.abaskolearning.com/student/tests/view-package/67108329787dbf28453024d4  "
              style={{ width: "100%", marginTop: "20px" }}
            >
              VIEW COURSE
            </Button>
          </div> */}
          {/* card 20 */}
          {/* <div className="cource">
            <a href="https://courses.abaskolearning.com/student/tests/view-package/671084377ebc78444c514e8d ">
              <img src={logo20} alt="" />
            </a>
            <h2 style={{marginTop:"2%"}}>Psychology + General Test </h2>
            <div className="price" style={{marginTop:"3%"}}>
              <span>
                ₹4500<div className="line"></div>
              </span>
              <span>₹4000</span>
            </div>
            <Button
              variant="outlined"
              href="https://courses.abaskolearning.com/student/tests/view-package/671084377ebc78444c514e8d   "
              style={{ width: "100%", marginTop: "20px" }}
            >
              VIEW COURSE
            </Button>
          </div> */}
          {/* card 21 */}
          <div className="cource">
            <a href="https://courses.abaskolearning.com/student/tests/view-package/671092262adf774d0314b789">
              <img src={logo20} alt="" />
            </a>
            <h2 style={{marginTop:"2%"}}>General Test </h2>
            <div className="price" style={{marginTop:"3%"}}>
              <span>
                ₹1900  <div className="line"></div>
              </span>
              <span>₹1500 </span>
            </div>
            <Button
              variant="outlined"
              href="https://courses.abaskolearning.com/student/tests/view-package/671092262adf774d0314b789    "
              style={{ width: "100%", marginTop: "20px" }}
            >
              VIEW COURSE
            </Button>
          </div>
          {/* card 22 */}
          <div className="cource">
            <a href="https://courses.abaskolearning.com/student/tests/view-package/671091ec69777f00c15f0cc1 ">
              <img src={logo21} alt="" />
            </a>
            <h2 style={{marginTop:"2%"}}>English </h2>
            <div className="price" style={{marginTop:"3%"}}>
              <span>
                ₹2000  <div className="line"></div>
              </span>
              <span>₹1000 </span>
            </div>
            <Button
              variant="outlined"
              href="https://courses.abaskolearning.com/student/tests/view-package/671091ec69777f00c15f0cc1     "
              style={{ width: "100%", marginTop: "20px" }}
            >
              VIEW COURSE
            </Button>
          </div>
          {/* card 23 */}
          <div className="cource">
            <a href="https://courses.abaskolearning.com/student/tests/view-package/6710925d60e182252520ab75 ">
              <img src={logo22} alt="" />
            </a>
            <h2 style={{marginTop:"2%"}}>General Test + English </h2>
            <div className="price" style={{marginTop:"3%"}}>
              <span>
                ₹3000<div className="line"></div>
              </span>
              <span>₹2000 </span>
            </div>
            <Button
              variant="outlined"
              href="https://courses.abaskolearning.com/student/tests/view-package/6710925d60e182252520ab75  "
              style={{ width: "100%", marginTop: "20px" }}
            >
              VIEW COURSE
            </Button>
          </div>
          {/* card 24 */}
          <div className="cource">
            <a href="https://courses.abaskolearning.com/student/tests/view-package/671085482adf774d0314b75e ">
              <img src={logo23} alt="" />
            </a>
            <h2 style={{marginTop:"2%"}}>Demo Classes </h2>
            <div className="price" style={{marginTop:"3%"}}>
              <span>
                ₹1000 <div className="line"></div>
              </span>
              <span>₹0</span>
            </div>
            <Button
              variant="outlined"
              href="https://courses.abaskolearning.com/student/tests/view-package/671085482adf774d0314b75e  "
              style={{ width: "100%", marginTop: "20px" }}
            >
              VIEW COURSE
            </Button>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurCources;
