import React from 'react';
import Layout from '../components/Layout/Layout';
import "../styles/HostWorkshop.css";
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import host_work_shop_lower_img from "../assets/spiral_host_workshop_side_logo.png";

const HostWorkshop = () => {
  return (
    <Layout>
      <section className='host_workshop_section'>
        <div className="host_wokshop_section_wrapper">
          <div className="shop_header_box">
            <h3>Host a Workshop at your institute</h3>
            <h5>Any institute can host workshop for their students by HyperNxt. The workshops can be Online or Offline.</h5>
          </div>

          <Grid container className='inst_fill_main_box'>
            <Grid item md={5.5} xs={12} >
              <div className='workshop_left_content'>
                <h5>Institute can host Following types of workshops:</h5>

                <div className="progress_box">

                  <p>Free Workshop- One day, online (Certificate of Participation for everyone)</p>

                  <p>Paid Workshop- 3-7 Days, Offline or Online (Certificate of Participation for everyone)</p>

                  <p>Colleges can host any inter college workshop at their college in association with HyperNxt</p>

                </div>

                <h5>Workshops offered by HyperNxt are best in class-Let's join Hands and host a workshop</h5>
              </div>

            </Grid>
            <Grid item md={5.5} xs={12}>
              <div className="fill_form_box">
                <h5>Fill out the Form Below</h5>
                <p>You just need to fill the form, we will get back to you soon.</p>


                <form action="" className='work_shop_form'>
                  <div className="work_shop_input_box_wrapper">
                    <div className="work_sho_input_box">
                      <label htmlFor="">Your Name *</label>
                      <input type="text" />
                    </div>
                  </div>

                  <div className="work_shop_input_box_wrapper">
                    <div className="work_sho_input_box">
                      <label htmlFor="">Your Designation *</label>
                      <select id="options" name="options">
                        <option value="">Select</option>
                        <option value="10">Fronted Devloper</option>
                        <option value="20">Backend Devloper</option>
                        <option value="30">Fullstack Devloper</option>
                      </select>
                    </div>
                  </div>

                  <div className="work_shop_input_box_wrapper">
                    <div className="work_sho_input_box">
                      <label htmlFor="">College Name *</label>
                      <input type="text" />
                    </div>
                  </div>

                  <div className="work_shop_input_box_wrapper">
                    <div className="work_sho_input_box">
                      <label htmlFor="">City *</label>
                      <input type="text" />
                    </div>
                  </div>

                  <div className="work_shop_input_box_wrapper">
                    <div className="work_sho_input_box">
                      <label htmlFor="">Contact or E-mail*</label>
                      <input type="text" />
                    </div>
                  </div>

                  <div className="work_shop_input_box_wrapper">
                    <div className="work_sho_input_box">
                      <label htmlFor="">Brief of Workshop you want to host*</label>
                      <textarea name="" id="" />
                    </div>
                  </div>

                  <div className="workshop_submit_btn_wrapper">
                    <button><Link>Submit</Link></button>
                  </div>

                </form>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="host_work_lower_img_box">
          <img src={host_work_shop_lower_img} alt="host_work_shop_lower_img" />
        </div>
      </section>



    </Layout>
  )
}

export default HostWorkshop;
