import React from 'react';
import "./CompaniesMarquee.css";
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { marqueeData } from './CompaniesMarqueeData';
import Marquee from 'react-fast-marquee';

const CompaniesMarquee = () => {
  return (
    <>
      <section className='companies_marquee_section'>
        <Grid container className='main_marq_container'>
          <Grid sm={5} xs={12} item className='marq_left_content'>
            <h4>Top Companies Hiring at HyperNxt Academy</h4>
            <Link to="/courses"><button className='explore_course_btn'>Explore Courses</button></Link>
          </Grid>

          <Grid sm={7} xs={12} item className='marq_right_content'>

            <Marquee
              speed={100}
            >
              <div className="marq_upper_right_content">
                {
                  marqueeData
                    ?.slice()
                    .sort(() => Math.random() - 0.5)
                    .map((item, i) => (
                      <div className="comp_logo_card" key={i}>
                        <img src={item.img} alt="placement_company_logo" className='marq_placement_logo' />
                      </div>
                    ))
                }
              </div>
            </Marquee>

            <Marquee
              speed={100}
            >
              <div className="marq_upper_right_content">
                {
                  marqueeData
                    ?.slice()
                    .sort(() => Math.random() - 0.5)
                    .map((item, i) => (
                      <div className="comp_logo_card" key={i}>
                        <img src={item.img} alt="placement_company_logo" className='marq_placement_logo' />
                      </div>
                    ))
                }
              </div>
            </Marquee>


          </Grid>
        </Grid>
      </section>
    </>
  )
}

export default CompaniesMarquee;
