import React from 'react';
import "./JobResume.css";
import { Link, useNavigate } from 'react-router-dom';
import { MdKeyboardArrowRight } from "react-icons/md";
import Grid from '@mui/material/Unstable_Grid2';
import apply_location_icon from "../../assets/apply_location_icon.png";
import { resumeCardData } from "./JobResumeData.js";

const JobResume = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    // navigate("/getAdmission")
    navigate("/applicationform")
  }

  return (
    <>
      <section className='upload_resume_section'>
        <div className="header_box">
          <div className='side_circle'>
            <div className="in_side_circle"></div>
          </div>
          <h5>HyperNext Academy students can professionally apply for jobs by uploading their resumes directly.</h5>
        </div>

        <Grid container className="apply_card_container">
          {
            resumeCardData.map((item, i) => (
              <Grid key={i} md={5} sm={12} sx={{ margin: "20px 0px" }}>
                <div className="resume_card_apply">
                  <h4><span>{item.header}</span></h4>
                  <h6>{item.experience}</h6>
                  <p>{item.about}</p>

                  <div className="location_box">
                    <img src={apply_location_icon} alt="apply_location_icon" />
                    <h5><span>Location</span> <span>{item.location}</span></h5>
                  </div>

                  <button onClick={handleClick}>
                    <Link>Detailed JD and Application Form <MdKeyboardArrowRight /> </Link>
                  </button>
                </div>
              </Grid>
            ))
          }
        </Grid>
      </section>
    </>
  )
}

export default JobResume;
