
export const leadingCourses = [
  {
    course:"MERN",
    head:"CUET UG Batch",
    duration:" 6 months",
    startingDate:"01 Aug 2024",
    link:"/mern_program"
  },
  {
    course:"NEET",
    head:"1th - 5th Batch",
    duration:" 6 months",
    startingDate:"01 Aug 2024",
    link:"/mean_program"
  },
  {
    course:"CA",
    head:"6th - 10th Batch",
    duration:" 6 months",
    startingDate:"01 Aug 2024",
  },
  {
    course:"UPSE",
    head:"10th - 12th Batch ",
    duration:" 6 months",
    startingDate:"01 Aug 2024",
  },
  
]