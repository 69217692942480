import React from 'react'
import Layout from '../components/Layout/Layout'
import "../styles/Faq.css";
import Accordian from '../components/Accordian/Accordian';
import { FaWhatsapp } from "react-icons/fa6";

const Faq = () => {
  return (
    <Layout>
      <section className='faq_section'>
          <div className="faq_header_box">
            <h4>FAQs</h4>
            <h5>Can’t find the answer here? Reach out to us via email.</h5>
          </div>

          <div className="accordinans_box">
            <Accordian/>

            <div className="what_contact_box">
            <FaWhatsapp className='whatsapp_icon' />
            </div>
          </div>
      </section>
    </Layout>
  )
}

export default Faq
