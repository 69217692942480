import React from 'react';
import "./Accordian.css";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import { IoMdAdd } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { accordiansData } from "./AccordianData.js";

const Accordian = () => {
  const [expanded, setExpanded] = React.useState(null);

  const handleExpansion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <div>
      {
        accordiansData.map((item, i) => (
          <Accordion key={i}
            expanded={expanded === i}
            onChange={handleExpansion(i)}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 400 }}
            sx={{
              '& .MuiAccordion-region': { height: expanded === i ? 'auto' : 0 },
              '& .MuiAccordionDetails-root': { display: expanded === i ? 'block' : 'none' },
            }}
          >
            <AccordionSummary
              expandIcon={expanded === i ? <RxCross2 /> : <IoMdAdd />}
              aria-controls={`panel${i}-content`}
              id={`panel${i}-header`}
            >
              <Typography>{item.que}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {item.ans}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))
      }
    </div>
  );
};

export default Accordian;
