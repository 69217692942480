/** @format */

import React from "react";
import "../styles/RefundPolicy.css";
import Layout from "../components/Layout/Layout";

const RefundPolicy = () => {
  return (
    <>
      <Layout>
        <div id="refundpolicymain">
          <div id="">
            <h2 id="refundpolicymain-text">Refund Policy</h2>
          </div>
          <div id="refundpolicymain-paraone">
            <h3 className="refundpolicymain-h3">
              At Abasko Learning, a brand of Abaskeo Solution Pvt Ltd, we are dedicated to delivering high-quality educational services and a rewarding learning experience. To maintain the integrity and value of our offerings, we have a No Refund Policy for all courses, products, and services provided through our platform.
            </h3>
            <h3 className="refundpolicymain-h3">
              By enrolling in our courses or purchasing any services from Abasko Learning, you agree to this Refund Policy.
            </h3>
          </div>
          <div id="Eligibilitymain">
            <h3 id="Eligibilityheading">1. No Refund Policy</h3>
            <p id="Eligibilityp">
              All payments made to Abasko Learning for courses, digital content, or services are non-refundable. This applies to all types of courses, whether live sessions, pre-recorded content, or downloadable materials. Once a purchase is completed, it is final and non-refundable under any circumstances, including:
            </p>
            <ul id="Eligibilitymainul">
              <li id="Eligibilitymainli">
                Change of mind after purchase.
              </li>
              <li id="Eligibilitymainli">
                Personal scheduling conflicts or missed classes.
              </li>
              <li id="Eligibilitymainli">
                Inability to continue with the course after enrollment.
              </li>
              <li id="Eligibilitymainli">
                Issues related to accessing the course due to user-side technical problems.
              </li>
            </ul>
          </div>
          <div id="Eligibilitymain">
            <h3 id="Eligibilityheading">2. Course Cancellations or Modifications</h3>
            <p id="Eligibilityp">
              In rare cases where a course is canceled or significantly modified by Abasko Learning, we will notify the affected users. However, this will not entitle users to a refund. We may offer alternative solutions, such as course credits or enrollment in a different course.
            </p>
          </div>
          <div id="Eligibilitymain">
            <h3 id="Eligibilityheading">3. Account and Access Responsibility</h3>
            <p id="Eligibilityp">
              It is the user’s responsibility to ensure that they meet the technical requirements and have stable internet access to fully participate in the course. Abasko Learning will not provide refunds due to user-side issues, including but not limited to:
            </p>
            <ul id="Eligibilitymainul">
              <li id="Eligibilitymainli">
                Internet connectivity problems.
              </li>
              <li id="Eligibilitymainli">
                Inability to access the course due to device or browser compatibility issues.
              </li>
              <li id="Eligibilitymainli">
                Failure to meet course prerequisites or requirements.
              </li>
            </ul>
          </div>
          <div id="Eligibilitymain">
            <h3 id="Eligibilityheading">3. Payment Disputes</h3>
            <p id="Partialpara">
              If there is an issue with your payment or a billing discrepancy, please contact us immediately at [Insert Email]. We will work with you to resolve any payment-related concerns, but no refunds will be issued.
            </p>
          </div>
          <div id="Eligibilitymain">
            <h3 id="Eligibilityheading">Contact Us</h3>
            <p id="Securitypara">
              If you have any questions about this Privacy Policy or how we
              handle your information, please contact us at:
            </p>
            <p id="Securitypara"></p>
            <p id="Securitypara">Abasko Learning (Abaskeo Solution Pvt Ltd)  <br />Email: <a href="mailto:abasko@learning.com">abasko@learning.com</a><br />Phone: <a href="tel:918685911755">8685911755</a></p>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default RefundPolicy;
