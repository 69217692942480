/** @format */

import React from "react";
import "../styles/CookiesPolicy.css";
import Layout from "../components/Layout/Layout";

const CookiesPolicy = () => {
  return (
    <>
      <Layout>
        <div id="cookies-mainbody">
          <h3 id="cookies-maintext">Cookies Policy</h3>
          <h4 id="cookies-secondtext">
          At Abasko Learning, a brand of Abaskeo Solution Pvt Ltd, we strive to provide an optimal user experience on our website. To enhance your browsing experience, we use cookies and similar technologies. This Cookies Policy explains what cookies are, how we use them, and how you can manage your preferences.
          </h4>
          <h4 id="cookies-secondtext">
          By continuing to use the Abasko Learning website, you agree to the use of cookies as outlined in this policy.
          </h4>
          <div id="cookies-Whatmain">
            <h3 className="Areheading">1. What Are Cookies?</h3>
            <p id="Persistentpara">
            Cookies are small text files stored on your device (computer, tablet, or mobile phone) when you visit a website. These files allow websites to recognize your device, remember your preferences, and provide a more personalized experience. Cookies can be session-based (temporary) or persistent (stored on your device for a set period).
            </p>
          </div>
          <div id="cookies-Whatmain">
            <h3 className="Areheading">2. Types of Cookies We Use</h3>
            <p id="Ensurepara">Abasko Learning uses the following types of cookies:</p>
            <ul id="Sessionul">
              <li id="Sessionli">
              Strictly Necessary Cookies: These cookies are essential for the proper functioning of the website. They enable core functionalities such as secure login, navigation, and access to secure areas.
              </li>
              <li id="Sessionli">
              Performance Cookies: These cookies collect anonymous information on how users interact with our website. We use this data to improve the website’s performance and user experience. Tools like Google Analytics help us understand visitor behavior and make improvements.
              </li>
              <li id="Sessionli">
              Functionality Cookies: These cookies remember your preferences (such as language settings, course selections, and user accounts) to provide a customized experience each time you visit.
              </li>
              <li id="Sessionli">
              Targeting/Advertising Cookies: These cookies track your browsing habits to deliver tailored advertisements and content relevant to your interests. We may partner with third-party advertising networks to serve ads based on your activity on our site and other websites. 
              </li>
            </ul>
          </div>
          <div id="cookies-Whatmain">
            <h3 className="Areheading">3. Why We Use Cookies</h3>
            <p id="Ensurepara">We use cookies to:</p>
            <ul id="Sessionul">
              <li id="Sessionli">
              Provide a smooth and secure browsing experience.
              </li>
              <li id="Sessionli">
              Remember your preferences and login details.
              </li>
              <li id="Sessionli">
              Analyze website traffic and performance.
              </li>
              <li id="Sessionli">
              Deliver personalized content and advertising.
              </li>
              <li id="Sessionli">
              Optimize website functionality and usability.
              </li>
            </ul>
          </div>
          <div id="cookies-Whatmain">
            <h3 className="Areheading">4.  Third-Party Cookies</h3>
            <p id="Ensurepara">
            In addition to our own cookies, Abasko Learning may allow third-party cookies on our website for purposes like advertising, analytics, and social media integration. These third parties may include platforms such as Google, Facebook, and Instagram. We do not control these cookies, so we encourage you to review the cookie policies of these third-party services.
            </p>
          </div>
          <div id="cookies-Whatmain">
            <h3 className="Areheading">5. How to Manage Cookies</h3>
            <p id="Persistentpara">
            You have control over how cookies are used on your device. Most web browsers automatically accept cookies, but you can adjust your browser settings to block or delete cookies. Please note that blocking cookies may affect the functionality of certain parts of our website and your overall user experience.
            </p>
            <p id="Ensurepara">Here’s how to manage cookies in popular browsers:</p>
            <ul id="Sessionul">
              <li id="Sessionli">
              Google Chrome: Go to Settings > Privacy and security > Cookies and other site data.
              </li>
              <li id="Sessionli">
              Mozilla Firefox: Go to Settings > Privacy & Security > Cookies and Site Data.
              </li>
              <li id="Sessionli">
              Safari: Go to Preferences > Privacy > Manage Website Data.
              </li>
              <li id="Sessionli">
              Microsoft Edge: Go to Settings > Cookies and site permissions > Manage and delete cookies and site data.
              </li>
            </ul>
            <p id="Persistentpara"><br />
            For more information on managing cookies, visit [allaboutcookies.org]<br/>(<a href="https://www.allaboutcookies.org">https://www.allaboutcookies.org</a>).
            </p>
          </div>
          <div id="cookies-Whatmain">
            <h3 className="Areheading">6.  Consent to Use Cookies</h3>
            <p id="Ensurepara">
            By continuing to use Abasko Learning’s website without changing your cookie settings, you consent to our use of cookies in accordance with this policy. You can withdraw your consent at any time by disabling cookies in your browser or adjusting your cookie preferences.
            </p>
          </div>
          <div id="cookies-Whatmain">
            <h3 className="Areheading">7.  Changes to Our Cookies Policy</h3>
            <p id="Ensurepara">
            We may update this Cookies Policy from time to time to reflect changes in our practices or legal requirements. Any updates will be posted on this page with the revised effective date. We encourage you to review this policy regularly to stay informed about how we use cookies.
            </p>
          </div>
          <div id="privacy-startmain">
            <h3 id="Collectheading">Contact Us</h3>
            <p id="Securitypara">
              If you have any questions about this Privacy Policy or how we
              handle your information, please contact us at:
            </p>
            <p id="Securitypara"></p>
            <p id="Securitypara">Abasko Learning (Abaskeo Solution Pvt Ltd)  <br />Email: <a href="mailto:abasko@learning.com">abasko@learning.com</a><br />Phone: <a href="tel:918685911755">8685911755</a></p>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default CookiesPolicy;
