import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
// import Courses from './pages/Courses';
import Careers from './pages/Careers';
import Enquire from './pages/Enquire';
import AfterSubmitEnquire from './components/AfterSubmitEnquire/AfterSubmitEnquire';
import ContactUs from './pages/ContactUs';
import Faq from './pages/Faq';
import HostWorkshop from './pages/HostWorkshop';
// import Blog from './pages/Blog';
import About from './pages/About';
import GetAdmission from './pages/GetAdmission';
import ApplicationForm from './pages/ApplicationForm';
import MeanProgram from './pages/MeanProgram';
import MernProgram from './pages/MernProgram';
import ScrollTop from './components/ScrollTop/ScrollTop';
import Gallery from './pages/Gallery';
import CyberSecurity from './pages/CyberSecurity';
import Courses from './pages/Courses';
import Explore from './pages/Explore';
import Categories from './pages/Category';
import NewsEvents from './pages/NewsEvents';
import CourseModule from './pages/CourseModule';
// import NewBlogs from './pages/NewBlogsPage';
import NewBlogsPage from './pages/NewBlogsPage';
import TermsCondition from './pages/TermsCondition';
import PrivacyPolicy from './pages/PrivacyPolicy';
import RefundPolicy from './pages/RefundPolicy';
import ShippingPolicy from './pages/ShippingPolicy';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Category } from '@mui/icons-material';
import CookiesPolicy from './pages/CookiesPolicy';
import NcontactUs2 from './pages/Ncontactus2';

const App = () => {
  return (
    <>
      <BrowserRouter>
        <ScrollTop />
        <Routes>
          <Route path='/' element={<Home />} />

          <Route path='/courses' element={<Courses />} />

          <Route path='/category' element={<Categories />} />

          <Route path='/explore' element={<Explore />} />

          <Route path='/careers' element={<Careers />} />

          <Route path='/about' element={<About />} />

          <Route path='/enquire' element={<Enquire />} />

          <Route path='/submitEnquire' element={<AfterSubmitEnquire />} />

          <Route path='/getAdmission' element={<GetAdmission />} />

          <Route path='/course_module' element={<CourseModule />} />

          <Route path='/mean_program' element={<MeanProgram />} />

          <Route path='/mern_program' element={<MernProgram />} />

          <Route path='/applicationform' element={<ApplicationForm />} />

          <Route path='/cybersecurity' element={<CyberSecurity />} />

          <Route exact path='/contact' element={<ContactUs />} />

          <Route exact path='/gallery' element={<Gallery />} />

          <Route exact path='/news_events' element={<NewsEvents />} />

          <Route exact path='/hostworkshop' element={<HostWorkshop />} />

          {/* <Route exact path='/blogs' element={<Blog />} />  */}
          <Route exact path='/blogs' element={<NewBlogsPage />} />
          <Route exact path='/termscondition' element={<TermsCondition />} />
          <Route exact path='/cookiespolicy' element={<CookiesPolicy />} />
          <Route exact path='/privacypolicy' element={<PrivacyPolicy />} />
          <Route exact path='/refundpolicy' element={<RefundPolicy />} />
          <Route exact path='/shippingpolicy' element={<ShippingPolicy />} />
          <Route exact path='/contactus' element={<NcontactUs2 />} />

          <Route exact path='/faq' element={<Faq />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App;
