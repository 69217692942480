/** @format */

import React from "react";
import "../styles/TermsCondition.css";
import Layout from "../components/Layout/Layout";

const TermsCondition = () => {
  return (
    <>
      <Layout>
        <div className="termscon-main-body">
          <h3 className="termsconmaintext">Terms & Conditions</h3>
          <div className="termaallpara">
            <div className="termscon-paraonemain">
              <p className="termscon-paraone">
                Welcome to Abasko Learning, a brand of Abaskeo Solution Pvt Ltd. These Terms and Conditions govern your use of our website and services, including but not limited to our online courses, educational resources, and digital platforms. By accessing or using Abasko Learning’s services, you agree to comply with and be bound by these Terms and Conditions. Please read them carefully before using the platform.
              </p>
            </div>
            <div className="termscon-2one">
              <h3 className="accept-text">1. Acceptance of Terms</h3>
              <p id="accept-para">
                By accessing or using the Abasko Learning website and its services, you accept and agree to these Terms and Conditions in full. If you do not agree with any part of these terms, please discontinue using our website and services immediately.
              </p>
            </div>
            <div className="termscon-2one">
              <h3 className="accept-text">2.  Changes to Terms</h3>
              <p id="accept-para">
                We may revise these Terms and Conditions from time to time. Any changes will be posted on this page with an updated effective date. It is your responsibility to review these terms periodically. Continued use of the website after changes are made constitutes acceptance of the revised terms.
              </p>
            </div>
            <div className="termscon-2one">
              <h3 className="accept-text">3. Use of Services</h3>
              <p id="accept-para">
                You agree to use Abasko Learning’s website and services only for lawful purposes. You must not:
              </p>
              <ul className="Registrationul">
                <li className="Registrationli">
                  Use the website in any way that violates applicable local, national, or international laws or regulations.
                </li>
                <li className="Registrationli">
                  Engage in any conduct that restricts or inhibits the use or enjoyment of the website by others.
                </li>
                <li className="Registrationli">
                  Attempt to gain unauthorized access to any part of the website, its servers, or related systems.
                </li>
                <li className="Registrationli">
                  Reproduce, duplicate, or copy any part of the website or its content without our prior written permission.
                </li>
              </ul>
            </div>
            <div className="termscon-2one">
              <h3 className="accept-text">4.  User Accounts</h3>
              <p id="accept-para">
                To access certain features of Abasko Learning, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or breach of security.
              </p>
            </div>
            <div className="termscon-2one">
              <h3 className="accept-text">5. Payment and Refund Policy</h3>
              <ul className="Registrationul">
                <li className="Registrationli">
                  Course Fees: All fees for courses or services provided by Abasko Learning must be paid in full at the time of enrollment unless otherwise stated.
                </li>
                <li className="Registrationli">
                  Refunds: Refunds are subject to the terms outlined in our Refund Policy. Please review the Refund Policy carefully before making any payments.
                </li>
              </ul>
            </div>
            <div className="termscon-2one">
              <h3 className="accept-text">6.  Intellectual Property</h3>
              <p id="accept-para">
                All content on the Abasko Learning website, including but not limited to text, graphics, logos, videos, and course materials, is the intellectual property of Abaskeo Solution Pvt Ltd unless otherwise noted. Unauthorized use, reproduction, or distribution of this content without our prior written consent is strictly prohibited.
              </p>
              <p id="accept-para">
                You are granted a limited, non-exclusive, non-transferable license to access and use the content for personal, non-commercial purposes only.
              </p>
            </div>
            <div className="termscon-2one">
              <h3 className="accept-text">7.  Limitation of Liability</h3>
              <p id="accept-para">
                To the fullest extent permitted by law, Abaskeo Solution Pvt Ltd and Abasko Learning will not be liable for any direct, indirect, incidental, or consequential damages arising from your use of the website, its content, or services. This includes but is not limited to any errors or omissions in the content, loss of data, or unauthorized access to your account.
              </p>
            </div>
            <div className="termscon-2one">
              <h3 className="accept-text">8.  Third-Party Links</h3>
              <p id="accept-para">
                The Abasko Learning website may contain links to third-party websites. These links are provided for your convenience only. We do not endorse or take responsibility for the content, privacy practices, or terms and conditions of third-party websites. We encourage you to review the policies of any third-party site you visit.
              </p>
            </div>
            <div className="termscon-2one">
              <h3 className="accept-text">9.  Termination</h3>
              <p id="accept-para">
                We reserve the right to terminate or suspend your access to Abasko Learning services at any time without notice for conduct that we, in our sole discretion, believe violates these Terms and Conditions or is harmful to other users of the website.
              </p>
            </div>
            <div className="termscon-2one">
              <h3 className="accept-text">10.  Governing Law</h3>
              <p id="accept-para">
                These Terms and Conditions are governed by and construed in accordance with the laws of India. Any disputes arising under these terms will be subject to the exclusive jurisdictions of India’s law.
              </p>
            </div>
            <div className="termscon-2one">
              <h3 className="accept-text">11.  Privacy Policy</h3>
              <p id="accept-para">
                Your use of the Abasko Learning website is also governed by our [Privacy Policy]. Please review it carefully to understand how we collect, use, and protect your personal information.
              </p>
            </div>
            <div className="termscon-2one">
              <h3 id="Collectheading">Contact Us</h3>
              <p id="Securitypara">
                If you have any questions about this Privacy Policy or how we
                handle your information, please contact us at:
              </p>
              <p id="Securitypara"></p>
              <p id="Securitypara">Abasko Learning (Abaskeo Solution Pvt Ltd)  <br />Email: <a href="mailto:abasko@learning.com">abasko@learning.com</a><br />Phone: <a href="tel:918685911755">8685911755</a></p>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default TermsCondition;
