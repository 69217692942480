import React from 'react'
import Layout from '../components/Layout/Layout';
import "../styles/GetAdmission.css";
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const GetAdmission = () => {

  const navigate = useNavigate();

  const handleClick = () =>{
    navigate("/submitEnquire")
  }

  return (
    <Layout>
      <section className='get_admission_main'>
        <div className="getAdmission_main_form_box">
          <div className="get_admission_header_box">
            <h4>Get Admission Now</h4>
            <h6>Fill out the form to join HyperNxt Academy</h6>
          </div>

          <div className="form_box_wrapper">
            <form action="">
              <Grid container className='first_input_container'>
                <Grid item sm={3.5} xs={12} className='input_box'>
                  <label htmlFor="">First Name *</label>
                  <input type="text" />
                </Grid>
                <Grid item sm={3.5} xs={12} className='input_box'>
                  <label htmlFor="">Middle Name </label>
                  <input type="text" />
                </Grid>
                <Grid item sm={3.5} xs={12} className='input_box'>
                  <label htmlFor="">Last Name*</label>
                  <input type="text" />
                </Grid>
              </Grid>

              <Grid container className='first_input_container'>
                <Grid item sm={5.3} xs={12} className='input_box'>
                  <label htmlFor="">College Name *</label>
                  <input type="text" />
                </Grid>
                <Grid item sm={5.3} xs={12} className='input_box'>
                  <label htmlFor="">City * </label>
                  <input type="text" />
                </Grid>
              </Grid>

              <Grid container className='third_input_container'>
                <Grid item xs={12} className='input_box'>
                  <label htmlFor="">Selected Course *</label>
                  <select name="" id="">
                    <option value="">Select</option>
                    <option value="">MERN Stack (Basic Plan)</option>
                    <option value="">MERN Stack (Premium Plan)</option>
                  </select>
                </Grid>
              </Grid>

              <Grid container className='fourth_input_container'>
                <Grid item sm={5.3} xs={12} className='input_box'>
                  <label htmlFor="">Phone Number*</label>
                  <input type="number" />
                </Grid>
                <Grid item sm={5.3} xs={12} className='input_box'>
                  <label htmlFor="">Nationality* </label>
                  <select name="" id="">
                    <option value="">Indian</option>
                  </select>
                </Grid>
              </Grid>

              <Grid container className='fourth_input_container'>
                <Grid item sm={5.3} xs={12} className='input_box'>
                  <label htmlFor="">Highest Qualification*</label>
                  <input type="number" />
                </Grid>
                <Grid item sm={5.3} xs={12} className='input_box'>
                  <label htmlFor="">Specialization* </label>
                  <select name="" id="">
                    <option value="">None</option>
                  </select>
                </Grid>
              </Grid>

              <Grid container className='fifth_input_container'>
                  <Grid item xs={12} className='input_box'>
                    <label htmlFor="">Why do you want to join HyperNxt Academy?*</label>
                    <textarea className='getAdTextArea' name="" id=""></textarea>
                  </Grid>
              </Grid>

              <div className="get_admission_btn" onClick={handleClick}>
                <button>Submit</button>
              </div>
            </form>
          </div>
        </div>


      </section>
    </Layout>
  )
}

export default GetAdmission
